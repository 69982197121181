<template>
  <div class="app-container">
  <!-- 顶部按钮 -->
    <div class="top_btns clearfix">
      <div class="right_btns fr">
        <!-- <qzf-video vid="4467be5374e4ef7c2822482cae694041_4"></qzf-video> -->

        <qzf-button button_code="xc_gzb_tj" class="" type="primary" @success="handleCreate" size="small"   >
          <el-icon><CirclePlus /></el-icon><span  > 添加</span>
        </qzf-button>
        <qzf-button button_code="xc_gzb_fz" type="primary" size="small" @success="copySalary()"   >
          <el-icon><DocumentCopy /></el-icon><span  > 复制往期薪酬</span>
        </qzf-button>
        <qzf-button button_code="xc_gzb_sz" type="primary" size="small" @success="handleSetShebao()"   >
          <el-icon><Setting /></el-icon><span  > 设置</span>
        </qzf-button>
        <!-- <settings v-if="$buttonStatus('xc_gzb_pzkmpz')" name="凭证科目配置" :type="['code_salary_wages','code_salary_wages_admin','code_salary_sc','code_salary_zz','code_salary_xs','code_salary_lw','code_salary_qt','code_salary_dksb','code_salary_dkgjj','code_salary_djgs','code_salary_dkqt','code_salary_lwbc_in','code_salary_lwbc_out','code_salary_only_in','code_salary_only_out']"></settings> -->
      </div>
    </div>
    <!-- top -->
    <!-- list -->
    <el-table stripe :height="contentStyleObj" v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%; margin-top:10px;" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="序号" prop="id" align="center" width="60" type="index"></el-table-column>
      <el-table-column label="薪酬类型" min-width="200">
        <template #default="{row}">
          <span>{{row.wageType}}</span>
        </template>
      </el-table-column>
      <el-table-column label="薪酬所属期" min-width="120">
        <template #default="{row}">
          <span>{{row.period}}</span>
        </template>
      </el-table-column>
      <el-table-column label="人数" min-width="60">
        <template #default="{row}">
          <span>{{row.count}}</span>
        </template>
      </el-table-column>

      <el-table-column label="工资合计" min-width="120">
        <template #default="{row}">
           <span v-if="row.wageType != '个人生产经营所得(查账征收)' && row.wageType != '个人生产经营所得(核定征收)'">{{(row.amount1 + row.amount2).toFixed(2)}}</span>
           <span v-else>--</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="凭证号" min-width="140">
        <template #default="{row}">
          <span>{{row.voucher_no}}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="操作" align="center" min-width="160" class-name="small-padding fixed-width top_right_btns">
        <template #default="{row}">

          <!-- <el-tooltip content="凭证" placement="top">
            <i class="iconfont icon-pingzheng" @click="editVoucher(row)"></i>
          </el-tooltip> -->
          <!-- <el-tooltip content="编辑" placement="top"> -->
            <i class="iconfont icon-bianji" @click="editRow(row)" v-if="$buttonStatus('xc_gzb_bj')">编辑</i>
          <!-- </el-tooltip> -->
          <!-- <el-tooltip content="删除" placement="top" v-if="$buttonStatus('xc_gzb_sc')"> -->
            <i class="iconfont icon-shanchu" @click="delRow(row)" v-if="$buttonStatus('xc_gzb_sc')">删除</i>
          <!-- </el-tooltip> -->
          <!-- <el-tooltip content="设置" placement="top" v-if="$buttonStatus('xc_yg_sz')"> -->
            <i class="iconfont icon-guanlishezhi" @click="handleUpdate(row)" v-if="$buttonStatus('xc_gzb_sz')">设置</i>
          <!-- </el-tooltip> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- list end -->
    <addSalary ref="addSalary" @success="getList"></addSalary>
    <!-- 复制往期 -->
    <el-dialog :close-on-click-modal="false"
      v-model="dialogFormVisible"
      width="21%"
      destroy-on-close
      align-center
    >
      <template #header>
        <div style="font-size: 17px;">
          请输入复制比例（%）
        </div>
      </template>
      <el-form :model="tempbqf" style="margin: -12px 0px -20px 0px">
        <div style="margin-bottom: 16px;color: red;font-size: 18px;">注：工资薪酬会按照本比例复制</div>
        <el-form-item label="" :label-width="formLabelWidth">
          <el-input class="input" size="small" v-model="tempbqf.bfb" style="height: 32px;width: 89%;" :disabled="bfqShow"></el-input>
          <el-icon style="font-size: 23px;margin-left: 5px;" v-if="bfqShow" @click="editBfq"><Edit /></el-icon>
          <div class="icon iconfont icon-baocun" v-else style="margin-left: 5px;font-size: 23px;" @click="editSaveBfq"></div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogFormVisible = false">取消</el-button>
          <el-button size="small" type="primary" @click="saveBfq()">确认</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 社保设置 -->
    <el-dialog 
     :close-on-click-modal="false"
      v-model="dialogFormVisible2"
      width="700px"
      destroy-on-close
      title="社保设置"
    > 
      <div class="top_select">
        <div><el-checkbox v-model="info.status" :true-label="1" :false-label="0">是否开启社保设置</el-checkbox></div>
        <el-button type="success" size="small" @click="oneKeySet">一键配置</el-button>
      </div>
      <table class="content" cellspacing="0" width="100%" v-loading="setLoading">
        <tr class="top_bg center">
          <td  style="width:25%;">名称</td>
          <td class="center" style="width:25%;">公司比例（100%）</td>
          <td class="center" style="width:25%;">个人比例（100%）</td>
          <td class="center" style="width:25%;">是否开启</td>
        </tr>
        <tr class="center">
          <td>养老保险</td>
          <td><el-input v-model="info.yangLaoBlGs" :disabled="!info.status"></el-input></td>
          <td><el-input v-model="info.yangLaoBlGr" :disabled="!info.status"></el-input></td>
          <td> <el-checkbox v-model="info.yangLaoStatus" :true-label="1" :false-label="0" :disabled="!info.status"/></td>
        </tr>
        <tr class="center">
          <td>医疗保险</td>
          <td><el-input v-model="info.yiLiaoBlGs" :disabled="!info.status"></el-input></td>
          <td><el-input v-model="info.yiLiaoBlGr" :disabled="!info.status"></el-input></td>
          <td> <el-checkbox v-model="info.yiLiaoStatus" :true-label="1" :false-label="0" :disabled="!info.status"/></td>
        </tr>
        <tr class="center">
          <td>失业保险</td>
          <td><el-input v-model="info.shiYeBlGs" :disabled="!info.status"></el-input></td>
          <td><el-input v-model="info.shiYeBlGr" :disabled="!info.status"></el-input></td>
          <td> <el-checkbox v-model="info.shiYeStatus" :true-label="1" :false-label="0" :disabled="!info.status"/></td>
        </tr>
        <tr class="center">
          <td>工伤保险</td>
          <td><el-input v-model="info.gongShangBlGs" :disabled="!info.status"></el-input></td>
          <td><el-input v-model="info.gongShangBlGr" :disabled="!info.status"></el-input></td>
          <td> <el-checkbox v-model="info.gongShangStatus" :true-label="1" :false-label="0" :disabled="!info.status"/></td>
        </tr>
        <tr class="center">
          <td>生育保险</td>
          <td><el-input v-model="info.shengYuBlGs" :disabled="!info.status"></el-input></td>
          <td><el-input v-model="info.shengYuBlGr" :disabled="!info.status"></el-input></td>
          <td> <el-checkbox v-model="info.shengYuStatus" :true-label="1" :false-label="0" :disabled="!info.status"/></td>
        </tr>
        <tr class="center">
          <td>住房公积金</td>
          <td><el-input v-model="info.gjjBlGs" :disabled="!info.status"></el-input></td>
          <td><el-input v-model="info.gjjBlGr" :disabled="!info.status"></el-input></td>
          <td> <el-checkbox v-model="info.gjjStatus" :true-label="1" :false-label="0" :disabled="!info.status"/></td>
        </tr>
      </table>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogFormVisible2 = false">取消</el-button>
          <el-button size="small" type="primary" @click="saveSetting()" :loading="btnLoading">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { salarySheetList,delEaSalarySheet,oeClickReplicationOne,salarySocialSet,salarySocialSetList } from '@/api/salary.js'
import addSalary from './addSalary.vue'
export default {
  name: 'salary',
  components:{ addSalary },
  data() {
    return {
      temp:{
        comType: "个体工商户",
        zsfs: "据实预缴",
      },
      list:[],
      tempbqf:{},//存放复制比例
      dialogFormVisible:false,//复制往期薪酬
      bfqShow:false,//是否可以编辑复制比例
      dialogFormVisible2:false,
      info:{},
      btnLoading:false,
      comId:this.$store.getters['user/comInfo'].comId*1,
      setLoading:false
    }
  },
  created() {
    this.contentStyleObj= this.$getHeight(224)
    this.getList()
    this.initBus()
  },
  methods: {
    initBus(){
      this.$bus.off("salarySheetUpdate");
      this.$bus.on("salarySheetUpdate", (val) => {
        this.getList();
      });
    },
    //新增
    handleCreate() {
      this.dialogStatus = 'create'
      // this.temp.dateEnd = this.$store.getters.currentPeriod
      this.$refs.addSalary.openSalary()
    },
    //删除
    delRow(e) {
      if(this.$checkSettleStatus()) return
      this.$confirm("确认删除该薪酬表", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delEaSalarySheet({ id: e.id }).then(res => {
          if(res.data.msg == "success"){
            this.getList();
            this.$message({
              type: "success",
              message: "删除成功"
            });
          }
        });
      })
    },
    //
    handleUpdate(row) {
      if(this.$checkSettleStatus()) return
      this.$refs.addSalary.openSalary(row)
    },
    //列表展示
    getList() {
      salarySheetList({}).then(response => {
        this.list = response.data.data.list
      })
    },

    editRow(e) {
    if(e.wageType == '正常工资薪资'){
      this.$store.dispatch('commons/setParam', {salaryNormalId: e.id})
      this.$store.dispatch('tagsView/delCachedViewByName', "salaryNormal")
      this.$router.push({
        path: "/input/remuneration/salaryNormal",
        name: "salaryNormal"
      });
    }
    if(e.wageType == '个人生产经营所得(查账征收)'){
      this.$store.dispatch('commons/setParam', {salaryPersonId: e.id})
      this.$store.dispatch('tagsView/delCachedViewByName', "salaryPerson")
      this.$router.push({
        path: "/input/remuneration/salaryPerson",
        name: "salaryPerson",
      });
    }
    if(e.wageType == '个人生产经营所得(核定征收)'){
      this.$store.dispatch('commons/setParam', {salaryPersonHdzsId: e.id})
      this.$store.dispatch('tagsView/delCachedViewByName', "salaryPersonHdzs")
      this.$router.push({
        path: "/input/remuneration/salaryPersonHdzs",
        name: "salaryPersonHdzs",
      });
    }
    if(e.wageType == '劳务报酬所得'){
      this.$store.dispatch('commons/setParam', {salaryServicesId: e.id})
      this.$store.dispatch('tagsView/delCachedViewByName', "salaryServices")
      this.$router.push({
        path: "/input/remuneration/salaryServices",
        name: "salaryServices",
      });
    }
    if(e.wageType == '全年一次性奖金'){
      this.$store.dispatch('commons/setParam', {salaryBonusId: e.id})
      this.$store.dispatch('tagsView/delCachedViewByName', "salaryBonus")
      this.$router.push({
        path: "/input/remuneration/salaryBonus",
        name: "salaryBonus",
      });
    }
  },
  //复制往期薪酬
  copySalary() {
    this.tempbqf.bfb =100
    this.dialogFormVisible =true;
    this.bfqShow = true;
    },
    // 编辑复制比例
    editBfq(){
      this.bfqShow = false;
    },
    editSaveBfq(){
      this.bfqShow = true;
    },
    // 保存复制比例
    saveBfq(){
      this.tempbqf.bfb = Number(this.tempbqf.bfb);
      oeClickReplicationOne(this.tempbqf).then(e => {
        if(e.data.msg == '已存在数据不能再次生成') {
          this.$message({
            message: '复制失败！该薪资表已存在',
            type: 'warning'
          })
        } else if(e.data.msg == "success") {
          this.dialogFormVisible =false;
          this.$qzfMessage("复制成功", 3)
          this.getList()
        }
      });
    },
    handleSetShebao(){
      this.reset()
      this.dialogFormVisible2 = true
      this.setLoading = true
      salarySocialSetList({}).then(res=>{
        this.setLoading = false
        if(res.data.data.id){
          this.info = res.data.data
        }
      })
    },
    saveSetting(){
      this.btnLoading = true
      this.info.yangLaoBlGs = Number(this.info.yangLaoBlGs)
      this.info.yangLaoBlGr = Number(this.info.yangLaoBlGr)
      this.info.yiLiaoBlGs = Number(this.info.yiLiaoBlGs)
      this.info.yiLiaoBlGr = Number(this.info.yiLiaoBlGr)
      this.info.shiYeBlGs = Number(this.info.shiYeBlGs)
      this.info.shiYeBlGr = Number(this.info.shiYeBlGr)
      this.info.gongShangBlGs = Number(this.info.gongShangBlGs)
      this.info.gongShangBlGr = Number(this.info.gongShangBlGr)
      this.info.shengYuBlGs = Number(this.info.shengYuBlGs)
      this.info.shengYuBlGr = Number(this.info.shengYuBlGr)
      this.info.gjjBlGr = Number(this.info.gjjBlGr)
      this.info.gjjBlGs = Number(this.info.gjjBlGs)
      this.info.comId = this.comId
      salarySocialSet(this.info).then(res=>{
        this.btnLoading = false
        if(res.data.msg == 'success'){
          this.$qzfMessage("保存成功")
          this.dialogFormVisible2 = false
        }
      })
    },
    reset(){
      this.info = {
        yangLaoBlGs:"",
        yangLaoBlGr:"",
        yangLaoStatus:0,
        yiLiaoBlGs:"",
        yiLiaoBlGr:"",
        yiLiaoStatus:0,
        shiYeBlGs:"",
        shiYeBlGr:"",
        shiYeStatus:0,
        gongShangBlGs:"",
        gongShangBlGr:"",
        gongShangStatus:0,
        shengYuBlGs:"",
        shengYuBlGr:"",
        shengYuStatus:0,
        gjjBlGr:"",
        gjjBlGs:"",
        gjjStatus:0,
        status:0
      }
    },
    oneKeySet(){
      this.info.yangLaoBlGs = '16' 
      this.info.yangLaoBlGr = '8'
      this.info.yangLaoStatus = 1

      this.info.yiLiaoBlGs = '8' 
      this.info.yiLiaoBlGr = '2'
      this.info.yiLiaoStatus = 1

      this.info.shiYeBlGs = '0.8' 
      this.info.shiYeBlGr = '0.2'
      this.info.shiYeStatus = 1

      this.info.gongShangBlGs = '0.16' 
      this.info.gongShangBlGr = '0'
      this.info.gongShangStatus = 1

      this.info.shengYuBlGs = '0' 
      this.info.shengYuBlGr = '0'
      this.info.shengYuStatus = 1

      this.info.gjjBlGs = '12' 
      this.info.gjjBlGr = '12'
      this.info.gjjStatus = 1
      this.info.status = 1
    }
    
  }
}
</script>

<style scoped lang="scss">
.icon-bianji,.icon-shanchu,.icon-guanlishezhi,.icon-pingzheng {
    margin: 0 5px;
    cursor: pointer;
    color: var(--themeColor,#17a2b8);
    font-size: 13px;
    line-height: 24px;
}
.pagination{
  margin-top: 15px;
}
.content{
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  // border-right: none;
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-bottom:1px solid #c2c2c2;
    line-height: 38px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  }
}
.center{
  text-align: center;
}
.top_select{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom:10px;
}
</style>

<style lang="scss">
  .classPrompt{
    .el-message-box__container{
      color:red
    }
}
</style>