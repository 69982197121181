<template>
  <div>
    <!-- 筛选 -->
    <div class="top_select">
      <div>
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <el-input
          placeholder="请输入公司名称/联系人"
          v-model="this.listQuery.customerName"
          style="width: 200px; margin-right: 5px"
          size="small"
          @keyup.enter="getList"
          clearable
        />
        <el-button type="primary" @click="getList()" size="small" icon="Search"
          >搜索</el-button
        >
        <selectuser
          code="coopCustom"
          v-model:userId="listQuery.userId"
          @change="getList()"
          style="width: 170px"
        ></selectuser>
      </div>
      <div>
        <el-dropdown
          split-button
          type="primary"
          @click="addCustomer"
          size="small"
          style="margin-right: 12px"
          :hide-on-click="false"
          v-if="$buttonStatus('hzkh_xzhzkh')"
        >
          <i class="iconfont icon-jiahao"></i> 新增合作客户
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <span
                  style="
                    width: 100px;
                    display: inline-block;
                    text-align: center;
                  "
                  @click="daoruFormVisible = true"
                  >导入客户</span
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-button
          type="success"
          size="small"
          plain
          @click="daochu"
          :loading="daochuLoading"
          icon="FolderOpened"
          :disabled="!$buttonStatus('hzkh_dc')"
          >导出</el-button
        >
      </div>
    </div>
    <!-- 客户列表 -->
    <el-table
      :data="customerList"
      style="width: 100%"
      border
      :height="contentStyleObj"
      @selection-change="handleSelectCustomer"
      v-loading="listLoading"
      stripe
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" width="55" align="center" fixed />
      <el-table-column
        type="index"
        label="序号"
        width="55"
        align="center"
        fixed
      />
      <el-table-column
        prop="customerName"
        label="公司名称"
        align="left"
        min-width="240"
        fixed
      >
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <div
              class="item_icon"
              style="
                width: 95%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              <span>{{ scope.row.customerName }}</span>
            </div>
            <el-tooltip content="复制" placement="top" effect="dark">
              <i
                class="iconfont icon-fuzhi1"
                style="color: var(--themeColor, #17a2b8); cursor: pointer"
                @click="$copyComName(scope.row.customerName)"
              >
              </i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="categoryName"
        label="服务项目"
        min-width="90"
        align="left"
      />
      <el-table-column prop="linkman" label="联系人" width="120" align="center">
        <template #default="scope">
          {{ scope.row.linkman }}
        </template>
      </el-table-column>
      <el-table-column
        prop="linkmanTel"
        label="联系人电话"
        min-width="125"
        align="center"
      >
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <div
              class="item_icon"
              style="
                width: 95%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              <span>{{ scope.row.linkmanTel }}</span>
            </div>
            <el-tooltip content="复制" placement="top" effect="dark">
              <i
                class="iconfont icon-fuzhi1"
                style="color: var(--themeColor, #17a2b8); cursor: pointer"
                @click="$copyComName(scope.row.linkmanTel)"
              >
              </i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="disCodes"
        label="销售经理"
        min-width="120"
        align="center"
      >
        <template #default="scope">
          <div v-if="scope.row.disCodes">
            {{ scope.row.disCodes.toString() }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="trackStarts"
        label="情况小记"
        min-width="150"
        align="left"
      >
        <template #default="scope">
          <span
            style="color: var(--themeColor, #17a2b8); cursor: pointer"
            @click="getStatusList(scope.row)"
            v-if="scope.row.trackStarts"
            ><el-icon style="margin-right: 5px"><chat-dot-round /></el-icon
            >{{ scope.row.trackStarts }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="disCodes"
        label="创建时间"
        width="100"
        align="center"
      >
        <template #default="scope">
          <span>{{ $parseTime(scope.row.createdAt, "{y}-{m}-{d}") }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        width="100"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column label="操作" align="center" width="350">
        <template #default="scope">
          <el-button
            :disabled="!$buttonStatus('hzkh_xj')"
            type="text"
            size="small"
            @click="updateStatus(scope.row)"
            icon="Notebook"
            plain
            style="margin: 0; padding: 5px 7px"
            >小记</el-button
          >
          <el-button
            :disabled="!$buttonStatus('hzkh_ht') || !scope.row.taxControlPanel"
            type="text"
            plain
            size="small"
            @click="moveContract(scope.row)"
            icon="Connection"
            style="margin: 0; padding: 5px 7px"
            >合同</el-button
          >
          <el-button
            :disabled="!$buttonStatus('hzkh_da')"
            type="text"
            plain
            size="small"
            @click="getArchivesList(scope.row)"
            icon="Reading"
            style="margin: 0; padding: 5px 7px"
            >档案</el-button
          >
          <el-button
            :disabled="!$buttonStatus('hzkh_xg')"
            type="text"
            size="small"
            @click="updateCustomer(scope.row)"
            icon="Edit"
            plain
            style="margin: 0; padding: 5px 7px"
            >编辑</el-button
          >
          <el-button
            :disabled="!$buttonStatus('hzkh_sc')"
            type="text"
            size="small"
            @click="handleTermination(scope.row)"
            icon="Delete"
            plain
            style="margin: 0; padding: 5px 7px; color: #f56c6c"
            >删除</el-button
          >
          <!-- <el-button
            :disabled="!$buttonStatus('hzkh_jy')"
            type="danger"
            size="small"
            @click="handleTermination(scope.row)"
            icon="Warning"
            plain
            >解约</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <div class="foot">
      <div>
        <el-button
          v-if="$buttonStatus('hzkh_plfp')"
          type="primary"
          size="small"
          :disabled="this.sels.length === 0"
          @click="allotFormVisible = true"
          icon="Share"
          >批量分配</el-button
        >
        <el-button
          type="danger"
          size="small"
          :disabled="this.sels.length === 0"
          @click="delALL"
          icon="Delete"
          plain
          >批量删除</el-button
        >
      </div>
      <div class="pagination" style="margin-top: 0">
        <qzf-pagination
          v-show="total > 0"
          :total="total"
          v-model:page="listQuery.page"
          v-model:limit="listQuery.limit"
          @pagination="getList()"
          type="coopCustom"
        />
      </div>
    </div>

    <!-- 新增编辑的弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="coopCustomerVisible"
      width="400px"
      :title="textMap[dialogStatus]"
    >
      <!-- 新增修改客户表单 -->
      <div class="content">
        <el-form
          ref="customerForm"
          :model="customerRuleForm"
          :rules="rules"
          label-width="100px"
          status-icon
          size="small"
        >
          <el-form-item label="公司名称：" prop="customerName">
            <el-input
              v-model="customerRuleForm.customerName"
              style="width: 182px"
              placeholder="请输入公司名称"
            />
          </el-form-item>
          <el-form-item label="联系人：" prop="linkman">
            <el-input
              v-model="customerRuleForm.linkman"
              placeholder="请输入联系人"
              style="width: 182px"
            />
          </el-form-item>
          <el-form-item label="电话：" prop="linkmanTel">
            <el-input
              v-model="customerRuleForm.linkmanTel"
              style="width: 182px"
              placeholder="请输入电话"
            />
          </el-form-item>
          <el-form-item label="地址：" prop="address">
            <el-input
              v-model="customerRuleForm.address"
              placeholder="请输入地址"
              style="width: 182px"
            />
          </el-form-item>
          <el-form-item label="服务项目" prop="categoryNameId">
            <selectBusiness
              v-model:category="customerRuleForm.categoryNameId"
              style="width: 182px; margin: 0"
              ref="selectBusiness"
            ></selectBusiness>
            <span
              style="
                margin-left: 4px;
                cursor: pointer;
                color: var(--themeColor, #17a2b8);
              "
              @click="toBusiness"
              >+业务类型</span
            >
          </el-form-item>
          <el-form-item label="销售经理：" prop="userId">
            <business
              code="coopCustom"
              v-model:userId="customerRuleForm.userId"
              style="width: 182px; margin-left: 0"
            ></business>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input
              v-model="customerRuleForm.remark"
              placeholder="请输入备注"
              style="width: 182px"
            />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="coopCustomerVisible = false" size="small"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="small"
            :loading="saveLoading"
            @click="createData()"
          >
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 批量分配弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="allotFormVisible"
      title="分配"
    >
      <span>销售经理：</span
      ><business code="coopCustom" v-model:userId="value"></business>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="allotFormVisible = false" size="small"
            >取消</el-button
          >
          <el-button type="primary" @click="saveAllot()" size="small">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 解约原因 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="breakFormVisible"
      title="解约原因"
      :before-close="handleClose"
    >
      <el-form
        :model="breakForm"
        :rules="rulesBreak"
        ref="ruleFormBreak"
        label-width="100px"
      >
        <el-form-item label="解约原因：" prop="terminationReason">
          <el-input
            v-model="breakForm.terminationReason"
            :rows="5"
            type="textarea"
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="breakFormVisible = false" size="small"
            >取消</el-button
          >
          <el-button type="primary" @click="moveTermination()" size="small">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 导入模板 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="daoruFormVisible"
      title="导入"
      height="50vh"
    >
      <el-form size="small" label-position="right" label-width="5px">
        <el-form-item label="">
          <el-input
            style="width: 60%; margin-right: 10px"
            v-model="path"
          ></el-input>
          <input
            size="small"
            ref="excel-upload-input"
            class="excel-upload-input"
            style="display: none"
            type="file"
            accept=".xlsx, .xls, .xml"
            @change="handleClick"
          />
          <el-button type="primary" @click="handleUpload()" size="small"
            >请选择文件</el-button
          >
        </el-form-item>
      </el-form>
      <div>
        <a href="javascript:;" @click="loadForm()">下载模板</a>
        <div style="text-align: right">
          <el-button
            size="small"
            type="primary"
            @click="uploadExcel"
            v-loading="daoruLoading"
            >上传</el-button
          >
        </div>
      </div>
    </el-dialog>
    <noteDialog ref="noteDialog" />
    <archivesDialog ref="archivesDialog" type="2" />
    <daoruDialog ref="daoruDialog" />
    <status ref="status"></status>
    <!-- 填写跟进进度 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="statusFormVisible"
      title="添加小记"
      width="520px"
    >
      <div>
        <div style="margin: 10px 0; padding-left: 13px; display: flex">
          <span style="color: red">*</span>记录：<el-input
            v-model="statusForm.trackStarts"
            :rows="5"
            type="textarea"
            style="width: 400px"
          />
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="statusFormVisible = false" size="small"
            >取消</el-button
          >
          <el-button type="primary" @click="submitStatus()" size="small">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-drawer title="业务类型" v-model="visibless" destroy-on-close size="60%">
      <businessType ref="businessTypee" @success="freshCate" />
    </el-drawer>
  </div>
</template>

<script>
import noteDialog from "./noteDialog.vue";
import archivesDialog from "./archivesDialog.vue";
import business from "./business.vue";
import daoruDialog from "./daoruDialog.vue";
import status from "./status.vue";
import {
  saveCustomerNew,
  customerListNew,
  updateCustomer,
  saveStatus,
  importCustomer,
  exportCustomerNew,
  delCustomerNew,
  saveCustomerState,
  batchDelCustomerNew,
} from "@/api/crm";
import selectBusiness from "../../components/selectBusiness.vue";
import businessType from "../../crmSettimg/businessType.vue";
export default {
  name: "coopCustom",
  components: {
    noteDialog,
    archivesDialog,
    business,
    daoruDialog,
    status,
    selectBusiness,
    businessType,
  },
  data() {
    let validateMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号！"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号！"));
      } else {
        return callback();
      }
    };
    return {
      visibless: false,
      listQuery: {
        page: 1,
        limit: 20,
        customerType: "2",
        customerName: null,
      },
      allotFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "编辑",
        create: "新增",
      },
      listLoading: false,
      daoruFormVisible: false,
      rawFile: "",
      path: "",
      breakFormVisible: false,
      coopCustomerVisible: false,
      customerRuleForm: {
        id: "",
        customerName: "",
        userId: [],
        linkman: "",
        linkmanTel: "",
        address: "",
        remark: "",
        customerType: "2",
      },
      breakForm: {
        id: "",
        terminationReason: "",
        customerType: "3",
      },
      rules: {
        customerName: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
        ],
        userId: [
          { required: true, message: "请选择销售经理", trigger: "change" },
        ],
        linkman: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        linkmanTel: [
          { required: true, validator: validateMobile, trigger: "blur" },
        ],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
        categoryNameId: [
          { required: true, message: "请选择服务项目", trigger: "change" },
        ],
      },
      rulesBreak: {
        terminationReason: [
          {
            required: true,
            message: "请输入解约原因",
            trigger: "blur",
          },
        ],
      },
      keywords: "",
      customerList: [],
      total: "",
      sels: [],
      value: [],
      daoruLoading: false,
      daochuLoading: false,
      statusForm: {},
      statusFormVisible: false,
      saveLoading: false,
    };
  },
  created() {
    this.listQuery.limit = localStorage.getItem("coopCustom")
      ? localStorage.getItem("coopCustom") * 1
      : 20;
    this.contentStyleObj = this.$getHeight(248);
  },
  mounted() {
    this.getList();
    this.initBus();
  },
  methods: {
    initBus() {
      this.$bus.off("coopUpdate");
      this.$bus.on("coopUpdate", (val) => {
        this.getList();
      });
    },
    //获取合作客户列表
    getList() {
      this.listLoading = true;
      customerListNew(this.listQuery).then((res) => {
        this.listLoading = false;
        if (res.data.msg == "success") {
          this.customerList = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
    //导入合同
    loadForm() {
      window.open(
        "https://file.listensoft.net/mnt/v2/other/合作客户导入.xlsx" +
          "?" +
          new Date().getTime()
      );
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile;
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    uploadExcel() {
      this.daoruLoading = true;
      const param = new FormData(); // 创建form对象
      param.append("file", this.rawFile, this.rawFile.name);
      importCustomer(param).then((res) => {
        this.daoruLoading = false;
        if (res.data.msg == "success") {
          this.getList();
          this.$bus.emit("contractUpdate");
          this.daoruFormVisible = false;
          this.$refs.daoruDialog.init(res.data.data);
        }
      });
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "请不要上传大于1M的文件。",
        type: "warning",
      });
      return false;
    },
    //小计
    getRecordList(row) {
      this.$refs.noteDialog.getNoteList(row);
    },
    //档案
    getArchivesList(row) {
      this.$refs.archivesDialog.checkArchivesList(row);
    },
    //新增客户
    addCustomer() {
      this.resetForm();
      this.dialogStatus = "create";
      this.coopCustomerVisible = true;
      this.$nextTick(() => {
        this.$refs["customerForm"].clearValidate();
      });
    },
    //修改客户
    updateCustomer(row) {
      this.customerRuleForm = Object.assign({}, row);
      this.dialogStatus = "update";
      this.coopCustomerVisible = true;
      this.$nextTick(() => {
        this.$refs["customerForm"].clearValidate();
      });
    },
    createData() {
      this.$refs["customerForm"].validate((valid) => {
        if (valid) {
          if (!this.customerRuleForm.categoryNameId) {
            this.$qzfMessage("请选择服务项目", 1);
            return;
          }
          this.saveLoading = true;
          saveCustomerNew(this.customerRuleForm).then((res) => {
            this.saveLoading = false;
            if (res.data.msg == "success") {
              this.$bus.emit("contractUpdate");
              this.getList();
              this.coopCustomerVisible = false;
              this.$qzfMessage("保存成功");
            }
          });
        }
      });
    },
    resetForm() {
      this.customerRuleForm = {
        customerName: "",
        userId: [this.$store.getters["user/user"].userId],
        linkman: "",
        linkmanTel: "",
        address: "",
        remark: "",
        customerType: "2",
      };
    },
    //业务类型
    toBusiness() {
      this.visibless = true;
    },
    freshCate() {
      this.$refs.selectBusiness?.getCategory();
    },
    handleSelectCustomer(sels) {
      this.sels = sels;
    },
    //解约
    handleTermination(row) {
      this.$confirm("确认删除客户吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delCustomerNew({ id: row.id }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
    //批量删除
    delALL() {
      this.$confirm("确认批量删除客户吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = [];
          this.sels.map((item) => {
            ids.push({
              id: item.id,
            });
          });
          batchDelCustomerNew(ids).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
    clickBreak() {
      this.resetBreakForm();
      this.breakFormVisible = true;
    },
    resetBreakForm() {
      this.breakForm = {
        id: "",
        terminationReason: "",
        customerType: "3",
      };
    },
    moveTermination() {
      this.$refs["ruleFormBreak"].validate((valid) => {
        if (valid) {
          if (this.breakForm.id == "") {
            let ids = [];
            this.sels.map((item) => {
              ids.push({
                id: item.id,
                customerType: "3",
                terminationReason: this.breakForm.terminationReason,
              });
            });
            saveStatus(ids).then((res) => {
              if (res.data.msg == "success") {
                this.$qzfMessage("修改成功");
                this.$bus.emit("termUpdate");
                this.$bus.emit("contractUpdate");
                this.getList();
                this.breakFormVisible = false;
                this.breakForm.terminationReason = "";
              }
            });
          } else {
            updateCustomer(this.breakForm).then((res) => {
              if (res.data.msg == "success") {
                this.$qzfMessage("解约成功");
                this.$bus.emit("termUpdate");
                this.$bus.emit("contractUpdate");
                this.getList();
                this.breakFormVisible = false;
                this.resetBreakForm();
              }
            });
          }
        }
      });
    },
    //批量分配
    saveAllot() {
      if (this.value.length == 0) {
        this.$qzfMessage("请选择销售经理", 1);
        return;
      }
      let params = [];
      this.sels.map((item) => {
        params.push({
          id: item.id,
          userId: this.value,
          customerType: item.customerType,
          typeName: "1",
        });
      });
      saveStatus(params).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("分配成功");
          this.getList();
          this.allotFormVisible = false;
          this.value = [];
        }
      });
    },
    //跳转传参
    moveContract(row) {
      this.$parent.$parent.$parent.moveContract(row.customerName);
      // this.$store.dispatch("commons/setParam", { customerId: row.id });
      // this.$store.dispatch("tagsView/delCachedViewByName", "contractList");
      // this.$router.push({
      //   name: "contractList",
      //   params: { customerId: row.id },
      // });
    },
    //查看跟进进度
    getStatusList(row) {
      this.$refs.status.getStatusList(row);
    },
    //导出
    daochu() {
      this.daochuLoading = true;
      let ids = [];
      this.sels.map((v) => {
        ids.push(v.id);
      });
      let param = {
        query: this.listQuery,
        ids: ids,
      };
      exportCustomerNew(param).then((res) => {
        this.daochuLoading = false;
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
    //添加小记
    updateStatus(row) {
      this.statusForm.customerId = row.id;
      this.statusForm.trackStarts = "";
      this.statusFormVisible = true;
    },
    submitStatus() {
      if (!this.statusForm.trackStarts) {
        this.$qzfMessage("请填写小记", 1);
        return;
      }
      saveCustomerState(this.statusForm).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("添加成功");
          this.getList();
          this.statusFormVisible = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top_select {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}
.note_box {
  height: 500px;
}
.note_content {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: flex-end;
  padding-bottom: 20px;
}
.note_content img {
  width: 300px;
  margin-top: 5px;
}
.el-form-item {
  margin-bottom: 22px !important;
}
.time {
  font-size: 18px;
}
.note {
  font-size: 14px;
  margin-top: 5px;
}
.foot {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.record {
  font-size: 14px;
  color: #333;
  font-weight: 600;
}
.record::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
</style>
