<template>
  <div class="app-container">
    <!-- 顶部按钮 -->
    <div class="top_select">
      <div>
        <el-input
          placeholder="请输入姓名查询"
          v-model="listQuery.name"
          style="width: 150px;"
          size="small"
          clearable
          @keyup.enter="getList()"
        />

        <el-date-picker
          v-model="listQuery.beginPeriod"
          type="month"
          placeholder="所属期起"
          size="small"
          value-format="YYYYMM"
          style="width: 100px;margin-left: 10px;"
        >
        </el-date-picker>
        --
        <el-date-picker
          v-model="listQuery.endPeriod"
          type="month"
          placeholder="所属期止"
          size="small"
          value-format="YYYYMM"
          style="width: 100px"
        >
        </el-date-picker>
        <el-button
          @click="getList()"
          type="primary"
          size="small"
          style="margin-left: 10px;"
        >
          <el-icon><Search /></el-icon><span> 查询</span>
        </el-button>
      </div>
      <div>
        <el-button @click="daochu()" type="primary" size="small" icon="Folder" :disabled="!$buttonStatus('xc_hzb_dc')">
          导出
        </el-button>
        <el-button
          @click="dayin()"
          type="success"
          size="small"
          icon="Folder"
          style="margin-left: 10px;"
          :disabled="!$buttonStatus('xc_hzb_dy')"
        >
          打印
        </el-button>
      </div>
    </div>

    <!-- 顶部按钮 end -->
    <!-- 内容 -->
    <el-table
      stripe
      :data="list"
      v-loading="isLoading"
      :height="contentStyleObj"
      style="width: 100%; margin-top: 10px;"
      show-summary
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column
        align="center"
        type="index"
        fixed
        width="70"
        label="序号"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        fixed
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        label="身份证号"
        fixed
        width="160"
        align="center"
      >
        <template #default="{row}">
          {{row.idNo}}
        </template>
      </el-table-column>
      <el-table-column label="账期" width="90" align="center">
        <template #default="{row}">
          {{ row.period }}
        </template>
      </el-table-column>
      <el-table-column label="费用类型" width="100" align="center">
        <template #default="{row}">
          {{ row.feeType }}
        </template>
      </el-table-column>
      <el-table-column label="应发工资" align="center">
        <el-table-column
          align="center"
          label="工资"
          width="120"
          prop="salary"
        ></el-table-column>
        <el-table-column
          align="center"
          label="福利费"
          width="120"
          prop="subsidy"
        ></el-table-column>
      </el-table-column>
      <el-table-column
        label="本期免税收入"
        width="120"
        prop="mssr"
        align="center"
      >
      </el-table-column>
      <el-table-column label="代扣社保" align="center">
        <el-table-column
          prop="deductSbPension"
          label="养老保险"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="deductSbMedical"
          label="医疗保险"
          width="120"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="deductSbUnemployment"
          label="失业保险"
          width="120"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="deductSbTotal"
          label="合计"
          width="120"
        >
        </el-table-column>
      </el-table-column>
      <el-table-column
        align="center"
        prop="deductHouseFund"
        label="代扣公积金"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="childrenEducation"
        label="累计子女教育"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="continuingEducation"
        label="累计继续教育"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="housingLoan"
        label="累计住房贷款"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="housingRent"
        label="累计住房租金"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="support"
        label="累计赡养老人"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="babyReduction"
        label="累计婴幼儿照护费用"
        min-width="170"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="personalPension"
        label="个人养老金"
        min-width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="deductOther"
        label="其他扣除"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="deductIncomeTax"
        label="本期代扣个税"
        width="120"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="actualSalary"
        label="实发工资"
        width="120"
      >
      </el-table-column>
      <el-table-column align="center" label="备注" width="120">
        <template #default="{row}">
          {{ row.remark }}
        </template>
      </el-table-column>
    </el-table>
    <!-- 内容 end -->
  </div>
</template>
<script>
import { salaryHzList } from "@/api/salary.js";
import { salaryHzSheet } from "@/api/export";
import { salaryHzPdf } from "@/api/printSet";
export default {
  data() {
    return {
      list: [],
      listQuery: {
        sheetId: this.$store.getters["commons/params"].salaryNormalId,
        name: "",
        beginPeriod:this.$store.getters["user/comInfo"].period,
        endPeriod:this.$store.getters["user/comInfo"].period,
      },
      isLoading: false,
    };
  },
  created() {
    this.contentStyleObj = this.$getHeight(180);
    this.getList();
  },
  methods: {
    getList() {
      if ((this.listQuery.beginPeriod && !this.listQuery.endPeriod) || (this.listQuery.endPeriod && !this.listQuery.beginPeriod)) {
        this.$message({
          showClose: true,
          message: "请选择正确的账期期间",
          type: "warning",
        });
        return;
      }
      this.isLoading = true;
      salaryHzList(this.listQuery).then((res) => {
        this.isLoading = false;
        this.list = res.data.data.list ? res.data.data.list : [];
      });
    },
    daochu() {
      salaryHzSheet(this.listQuery).then((res) => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
    dayin() {
      salaryHzPdf(this.listQuery).then((res) => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
