<template>
  <el-dialog :close-on-click-modal="false" v-model="dialogFormVisible" title="收款登记" width="815px">
    <el-scrollbar :maxHeight="contentStyleObj">
      <div style="padding-right: 15px;" v-loading="loading">
        <div class="record">基础信息：</div>
        <el-form :inline="true" style="width:100%" label-width="95px">
          <el-form-item label="公司名称：" @click.prevent="() => { }">
            <el-input placeholder="公司名称" v-model="form.customerName" size="small" style="width:268px" disabled />
          </el-form-item>
          <el-form-item label="收款人：" @click.prevent="() => { }">
            <el-input placeholder="收款人" v-model="form.paymentName" size="small" style="width:268px" />
          </el-form-item>
          <el-form-item label="备注说明：" @click.prevent="() => { }">
            <el-input placeholder="备注说明" v-model="form.remark" size="small" type="textarea" style="width:662px" />
          </el-form-item>
        </el-form>
        <div class="record" style="float: left;">
          服务项目：
        </div>
        <div>
          <table class="content" cellspacing="0" width="100%">
            <tr class="top_bg center">
              <td style="width:18%">服务项目</td>
              <td style="width:18%">付款周期</td>
              <td style="width:18%">开始时间</td>
              <td style="width:18%">结束时间</td>
              <td style="width:18%">金额</td>
              <td style="width:10%">操作</td>
            </tr>
            <tr class="center" v-for="(item, index) in form.server" :key="index">
              <td style="width:18%">
                <selectBusiness v-model:category="item.categoryNameId" size="small" style="width: 100%;"
                  class="boxShadowNone boxShadowNone2" :edit="item.id ? true : false"></selectBusiness>
              </td>
              <td style="width:18%">
                <el-select v-model="item.disposable" placeholder="请选择付款周期" size="small" style="width: 100px"
                  class="boxShadowNone boxShadowNone2" :disabled="item.id">
                  <el-option label="周期性" value="0" />
                  <el-option label="一次性" value="1" />
                </el-select>
              </td>
              <td style="width:18%">
                <el-date-picker type="date" value-format="YYYY-MM-DD" placeholder="请选择开始时间" size="small"
                  style="width: 100%;" v-model="item.contractStartTime" :disabled="item.disposable == '1' || item.id"
                  class="boxShadowNone boxShadowNone2" />
              </td>
              <td style="width:18%">
                <el-date-picker type="date" value-format="YYYY-MM-DD" placeholder="请选择结束时间" size="small"
                  style="width: 100%;" v-model="item.contractEndTime" :disabled="item.disposable == '1' || item.id"
                  class="boxShadowNone boxShadowNone2" />
              </td>
              <td style="width:18%">
                <el-input v-model="item.contractAmount" size="small" style="width: 100%;" placeholder="请输入合同金额"
                  class="boxShadowNone boxShadowNone2" :disabled="item.id" />
              </td>
              <td style="width:10%"></td>
            </tr>
            <tr>
              <td style="text-align: right;" colspan="6">
                合同金额：¥{{ totalAmount }}
              </td>
            </tr>
          </table>
        </div>
        <div class="record" style="float: left;margin-top: 10px;">
          收款登记：
        </div>
        <div style="float: right;color: var(--themeColor,#17a2b8);cursor: pointer;margin-top: 10px;"
          @click="addSkxx(form.records)" v-if="totalAmount2 != 0">
          +添加收款信息
        </div>
        <div>
          <table class="content" cellspacing="0" width="100%">
            <tr class="top_bg center">
              <td style="width: 15%">收款时间</td>
              <td style="width: 15%">服务项目</td>
              <td style="width: 15%">收款期起</td>
              <td style="width: 15%">收款期止</td>
              <td style="width: 15%">收款金额</td>
              <td style="width: 15%">收款方式</td>
              <td style="width: 10%">操作</td>
            </tr>
            <tr class="center" v-for="(item, index) in form.records" :key="index">
              <td style="width:15%">
                <el-date-picker type="date" value-format="YYYY-MM-DD" placeholder="请选择收款时间" size="small"
                  style="width: 100%;" v-model="item.paymentTime" class="boxShadowNone boxShadowNone2"
                  :disabled="item.id" />
              </td>
              <td style="width:15%">
                <el-select v-model="item.categoryNameId" placeholder="请选择" filterable size="small" :disabled="item.id"
                  @change="changeCategory(item)">
                  <el-option v-for="item in options" :key="item.id" :label="item.categoryName" :value="item.id" />
                </el-select>
              </td>
              <td style="width:15%">
                <el-date-picker type="date" value-format="YYYY-MM-DD" placeholder="请选择收款期起" size="small"
                  style="width: 100%;" v-model="item.collectionStartTime" class="boxShadowNone boxShadowNone2"
                  :disabled="item.id || item.status" />
              </td>
              <td style="width:15%">
                <el-date-picker type="date" value-format="YYYY-MM-DD" placeholder="请选择收款期止" size="small"
                  style="width: 100%;" class="boxShadowNone boxShadowNone2" v-model="item.collectionEndTime"
                  :disabled="item.id || item.status" />
              </td>
              <td style="width:15%">
                <el-input v-model="item.paymentAmount" size="small" style="width: 100%;"
                  class="boxShadowNone boxShadowNone2" :disabled="item.id" />
              </td>
              <td style="width: 15%">
                <selectMethod v-model:methodId="item.paymentMethodId" style="width: 100%" :options="methodOptions" @success2="getMethod" :disabled="item.id"/>
              </td>
              <td style="width:10%">
                <span style="color: red;cursor: pointer;" @click="delSkxx(form.records, index)"
                  v-if="!item.id">删除</span>
              </td>
            </tr>
            <tr>
              <td style="text-align: right;" colspan="7">
                收款金额：¥{{ totalAmount1 }} 未收款金额：¥{{ totalAmount2 }}
              </td>
            </tr>
          </table>
        </div>
        <div class="record" style="margin-top: 10px;">收款附件：</div>
        <upload @uploadimgzj="imgUrl" style="margin-left:0" :images="form.paymentAnnex" />
      </div>
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="saveCollection()" size="small" :loading="btnLoading">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import selectBusiness from "../../components/selectBusiness.vue";
import upload from "@/components/uploadImg/upload";
import selectMethod from "./selectMethod.vue";
import {
  saveCollectionRecord,
  contractCategoryList,
  contractInfo,
  paymentMethodList
} from "@/api/crm";
function deepClone(obj) {
  let objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        //判断ojb子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === "object") {
          objClone[key] = deepClone(obj[key]);
        } else {
          //如果不是，简单复制
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
}
export default {
  name: "",
  components: {
    selectBusiness,
    upload,
    selectMethod,
  },
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      form: {
        id: null,
        customerId: null,
        customerName: "",
        contractNum: "",
        contactsName: "",
        userId: null,
        contactsPhone: "",
        contractSignTime: "",
        customerSource: "",
        remark: "",
        server: [
          {
            categoryNameId: null,
            disposable: "1",
            contractStartTime: "",
            contractEndTime: "",
            contractAmount: "",
          },
        ],
        records: [
          {
            categoryNameId: null,
            collectionStartTime: "",
            collectionEndTime: "",
            paymentAmount: "",
            paymentTime: "",
          },
        ],
      },
      contentStyleObj: {},
      totalAmount: 0,
      totalAmount1: 0,
      totalAmount2: 0,
      options: [],
      category: [],
      date: "",
      btnLoading: false,
      loading: false,
      methodOptions:[]
    };
  },
  computed: {
    //金额合计

    totalAmount() {
      let amount = 0;
      this.form.server.map((v) => {
        if (v.contractAmount) {
          amount += Number(v.contractAmount);
        }
      });
      return Number(amount.toFixed(2));
    },
    totalAmount1() {
      let amount = 0;
      this.form.records.map((v) => {
        if (v.paymentAmount) {
          amount += Number(v.paymentAmount);
        }
      });
      return Number(amount.toFixed(2));
    },
    totalAmount2() {
      let amount = 0;
      let count1 = 0;
      let count2 = 0;
      this.form.server.map((v) => {
        if (v.contractAmount) {
          count1 += Number(v.contractAmount);
        }
      });
      this.form.records.map((v) => {
        if (v.paymentAmount) {
          count2 += Number(v.paymentAmount);
        }
      });
      if (count1 && count1 >= count2) amount = Number(count1 - count2);
      return Number(amount.toFixed(2));
    },
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(280);
  },
  methods: {
    async init(row) {
      this.dialogFormVisible = true;
      this.loading = true;
      this.resetForm();
      this.getDay();
      this.getMethod()
      await contractInfo({ id: row.id }).then((res) => {
        if (res.data.msg == "success") {
          this.form = deepClone(res.data.data.list);
          if (this.form.records == null) {
            this.form.records = [];
          }
          this.form.paymentName = this.$store.getters["user/user"].cnName;
          this.getCategoryList();
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
      // this.form = Object.assign({}, row);
    },
    getMethod(){
      paymentMethodList({}).then(res=>{
        if(res.data.msg == 'success'){
          this.methodOptions = res.data.data.list ? res.data.data.list : [];
        }
      })
    },
    getDay() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month < 10 ? "0" + month : month;
      day = day <= 9 ? "0" + day : day;
      this.date = year + "-" + month + "-" + day;
    },
    getCategoryList() {
      contractCategoryList({ contractId: this.form.id }).then((res) => {
        this.options = res.data.data.list;
      });
    },
    //添加收款信息
    addSkxx(item) {
      item.push({
        categoryNameId: null,
        collectionStartTime: "",
        collectionEndTime: "",
        paymentAmount: "",
        paymentTime: this.date,
      });
    },
    //删除收款信息
    delSkxx(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
    },
    //获取图片
    imgUrl(val) {
      this.form.paymentAnnex = val;
    },
    saveCollection() {
      const hasMissingCategory = (items, key) => items.some((item) => !item[key]); // 检查是否有未选择的项目
      if (this.form.records.length == 0) {
        this.$qzfMessage("请添加收款信息", 1);
        return;
      }
      if (this.form.records[this.form.records.length - 1].id) {
        this.$qzfMessage("请确认是否已添加收款信息", 1);
        return;
      }
      if (hasMissingCategory(this.form.records, "categoryNameId")) {
        this.$qzfMessage("存在收款信息未选择服务项目", 1);
        return;
      }
      let amountStatus = false;
      this.form.records.map((v) => {
        if (!v.paymentAmount || v.paymentAmount == "0") {
          amountStatus = true;
          return;
        }
      });
      if (amountStatus) {
        this.$qzfMessage("请输入收款金额", 1);
        return;
      }
      if (hasMissingCategory(this.form.records, "paymentMethodId")) {
        this.$qzfMessage("存在收款信息未选择收款方式", 1);
        return;
      }
      this.form.records.map((v) => {
        v.paymentAmount = (v.paymentAmount * 1).toFixed(2) * 1;
      });
      this.btnLoading = true
      saveCollectionRecord(this.form).then((res) => {
        this.btnLoading = false;
        if (res.data.msg == "success") {
          this.$qzfMessage("操作成功");
          this.dialogFormVisible = false;
          this.$emit("success");
        }
      });
    },
    resetForm() {
      this.form = {
        id: null,
        customerId: null,
        customerName: "",
        contractNum: "",
        contactsName: "",
        userId: null,
        contactsPhone: "",
        contractSignTime: "",
        customerSource: "",
        remark: "",
        server: [
          {
            categoryNameId: null,
            disposable: "1",
            contractStartTime: "",
            contractEndTime: "",
            contractAmount: "",
          },
        ],
        records: [
          {
            categoryNameId: null,
            collectionStartTime: "",
            collectionEndTime: "",
            paymentAmount: "",
            paymentTime: "",
          },
        ],
        contractImg: "",
      };
    },
    changeCategory(item) {
      this.form.server.map((v) => {
        if (v.categoryNameId == item.categoryNameId) {
          if (v.disposable == "0") {
            item.collectionStartTime = v.contractStartTime;
            item.collectionEndTime = v.contractEndTime;
            item.status = false;
          } else {
            item.status = true;
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.record {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
}

.record::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}

.el-form-item__label {
  font-size: 13px;
}

.content {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;

  span {
    line-height: 28px;
    font-size: 13px;
  }

  tr {
    padding: 0;
    margin: 0;
  }

  td {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
    line-height: 25px;
    padding: 0 6px;
    color: #333;
    font-size: 13px;
  }
}

.center {
  text-align: center;
}

:deep(.el-textarea__inner) {
  min-height: 80px !important;
}

table {
  :deep(.el-input__wrapper) {
    box-shadow: none;
    background: none;
    border: none;
  }
}

.boxShadowNone2 {
  :deep(.el-select-v2__wrapper) {
    box-shadow: none;
    background: none;
    border: none;
  }
}

.el-form--inline .el-form-item:nth-child(2n) {
  margin-right: 0;
}

.el-form--inline .el-form-item:last-child {
  margin-right: 0;
}
</style>
