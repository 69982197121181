<template>
  <!-- <el-tabs tab-position="left" style="height: 100%;" type="border-card" v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="员工" name="first">
      <remunerationEmployee ref="remunerationEmployee"></remunerationEmployee>
    </el-tab-pane>
    <el-tab-pane label="工资表" name="second">
      <remunerationSalary ref="remunerationSalary"></remunerationSalary>
    </el-tab-pane>
    <el-tab-pane label="累计数据" name="third">
      <information ref="information"></information>
    </el-tab-pane>
  </el-tabs> -->
  <!-- <div :style="{ height: contentStyleObj }"> -->
    <qzf-tabs v-model:value="value" @tab-click="handleClick">
      <qzf-tabs-item label="员工" name="1" :cache="false">
        <remunerationEmployee ref="remunerationEmployee"></remunerationEmployee>
      </qzf-tabs-item>
      <qzf-tabs-item label="工资表" name="2" :cache="false">
        <remunerationSalary ref="remunerationSalary"></remunerationSalary>
      </qzf-tabs-item>
      <qzf-tabs-item label="累计数据" name="3" :cache="false">
        <information ref="information"></information>
      </qzf-tabs-item>
      <qzf-tabs-item label="汇总表" name="4" :cache="false">
        <collect ref="collect"></collect>
      </qzf-tabs-item>
    </qzf-tabs>
  <!-- </div> -->
</template>

<script>
import remunerationEmployee from "./components/employee.vue";
import remunerationSalary from "./components/salary.vue";
import information from "./components/information.vue";
import collect from "./components/collect.vue";
export default {
  name: "remuneration",
  components: { remunerationEmployee, remunerationSalary, information, collect },
  data() {
    return {
      value: "1",
      contentStyleObj: {}, //高度变化
    };
  },
  // deactivated() {
  //   if (!this.$getCachedViews("remuneration")) {
  //     //销毁 mitt bus
  //     this.$bus.off("salarySheetUpdate");
  //   }
  // },
  created() {
    this.contentStyleObj = this.$getHeight(190);
    // this.initBus();
  },
  methods: {
    initBus() {
      this.$bus.on("salarySheetUpdate", (val) => {
        if (this.$refs.remunerationSalary) {
          this.$refs.remunerationSalary.getList();
        }
      });
    },
  },
};
</script>

<style></style>
