<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisible"
    title="导出"
    width="570px"
    center
  >
    <div class="title">
      <i
        class="iconfont icon-gantanhao"
        style="color: #e6a23c; margin-right: 5px"
      ></i
      >请选择导出的模板格式
    </div>
    <div>
      <el-radio-group v-model="daochuStyle">
        <el-radio label="税局"
          >自然人标准导出模版<br /><span
            style="font-size: 12px; margin-top: 3px"
            >与自然人客户端【{{
              props.type == "salary" ? "收入及减除填写" : "人员信息采集"
            }}
            】的导出报表一致</span
          ></el-radio
        >
        <el-radio label="系统"
          >系统导出模板<br /><span style="font-size: 12px; margin-top: 3px"
            >普通Excel列表格式</span
          ></el-radio
        >
      </el-radio-group>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="daochuSure"
          size="small"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref } from "vue";
import { exportSalary } from "@/api/export";
const props = defineProps({
  comId: {
    type: Number,
    default: 0,
  },
  period: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "people",
  },
  listQuery: {
    type: Object,
  },
});
const sels = ref([]);
const daochuStyle = ref("系统");
const dialogVisible = ref(false);
const loading = ref(false);
const init = (item) => {
  sels.value = item;
  dialogVisible.value = true;
};
const daochuSure = () => {
  loading.value = true;
  let arr = [];
  if (props.type == "salary") {
    sels.value.forEach((item) => {
      arr.push(item.employeeId);
    });
  } else {
    sels.value.forEach((item) => {
      arr.push(item.id);
    });
  }
  let param = {
    period: props.period,
    comId: props.comId,
    empId: arr,
    type: props.type,
    style: daochuStyle.value,
    query: props.listQuery,
  };
  exportSalary(param).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      window.open(res.data.data.url);
      dialogVisible.value = true;
    }
  });
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.title {
  background-color: #fef6ed;
  font-size: 13px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #333;
  border: 1px solid #ffe1b7;
  border-radius: 3px;
}
</style>
