<template>
  <div>
    <div class="content_data">
      <div style="
          display: flex;
          align-items: center;
          width: 99%;
          margin: 0 auto;
          height: 70px;
        ">
        <div style="width: 40px">统计</div>
        <div class="each" style="width: 10%">
          <div class="icon_bg">
            <i class="iconfont icon-gongsi1"></i>
          </div>
          <div class="each_right">
            <div style="margin-bottom: 5px">签约合同客户</div>
            <div class="title">
              <span>{{ dataInfo.customerCount }}</span>家
            </div>
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-zichan"></i>
          </div>
          <div style="margin-left: 5px; width: calc(100% - 35px)">
            <div style="margin-bottom: 5px">签约合同</div>
            <div class="title">
              <span>{{ dataInfo.contractCount }}</span>份
            </div>
          </div>
        </div>
        <div class="each" style="width: 10%">
          <div class="icon_bg">
            <i class="iconfont icon-jiezhang"></i>
          </div>
          <div class="each_right">
            <div style="margin-bottom: 5px">合同总金额</div>
            <div class="title">
              <span>{{ $comdify(dataInfo.contractNumber) }}</span>元
            </div>
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-jizhanghesuan"></i>
          </div>
          <div class="each_right">
            <div style="margin-bottom: 5px">周期合同</div>
            <div class="title">
              <span>{{ dataInfo.zqContractNumber }}</span>元
            </div>
          </div>
        </div>
        <!-- <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-lishishujucaiji"></i>
          </div>
          <div class="each_right">
            <div style="margin-bottom:5px">应收款</div>
            <span>{{ $comdify(dataInfo.contractNumber) }}</span
            >元
          </div>
        </div> -->
        <div class="each" style="width: 10%">
          <div class="icon_bg">
            <i class="iconfont icon-jiezhang"></i>
          </div>
          <div class="each_right">
            <div style="margin-bottom: 5px">已收款</div>
            <div class="title">
              <span>{{ $comdify(dataInfo.contractFkNumber) }}</span>元
            </div>
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-jiezhangdayin1"></i>
          </div>
          <div class="each_right">
            <div style="margin-bottom: 5px">未收款</div>
            <div class="title">
              <span>{{ $comdify(dataInfo.contractWfkNumber) }}</span>元
            </div>
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-jizhangkehu2"></i>
          </div>
          <div class="each_right">
            <div style="margin-bottom: 5px">流失合同</div>
            <div class="title">
              <span>{{ dataInfo.jyContractCount }}</span>份
            </div>
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-caiwubaobiao"></i>
          </div>
          <div class="each_right">
            <div style="margin-bottom: 5px">流失金额</div>
            <div class="title">
              <span>{{ $comdify(dataInfo.jyContractNumber) }}</span>元
            </div>
          </div>
        </div>
        <div class="data_time">
          统计周期：<el-date-picker v-model="listQuery1.contractStartTime" type="month" placeholder="选择月" style="width: 90px"
            size="small" @change="getStatistics" value-format="YYYY-MM">
          </el-date-picker>-<el-date-picker v-model="listQuery1.contractEndTime" type="month" placeholder="选择月"
            style="width: 90px" size="small" @change="getStatistics" value-format="YYYY-MM">
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="top_select">
      <div class="select_left">
        <div class="every_select">
          <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
          <el-input placeholder="请输入公司名称/合同编号" v-model="listQuery.customerName" style="width: 200px; margin-right: 5px"
            size="small" @keyup.enter="getList" clearable />
          <el-button type="primary" @click="getList()" size="small" icon="Search">搜索</el-button>
        </div>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin: 11px 0px 0px 5px; width: 400px">
            <el-form-item label="联系人：" :label-width="formLabelWidth">
              <el-input v-model="listQuery.contactsName" size="small" placeholder="请输入联系人"
                style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="合同性质：" :label-width="formLabelWidth">
              <el-select v-model="listQuery.disposable" placeholder="请选择" size="small" style="width: 200px">
                <el-option label="全部" :value="null" />
                <el-option label="周期性" value="0" />
                <el-option label="一次性" value="1" />
              </el-select>
            </el-form-item>
            <el-form-item label="合同签订时间：" :label-width="formLabelWidth">
              <el-date-picker v-model="listQuery.contractSignTime" type="month" placeholder="选择月份" size="small"
                value-format="YYYY-MM" style="width: 200px" />
            </el-form-item>
            <el-form-item label="销售经理：" :label-width="formLabelWidth">
              <selectuser code="coopCustom" v-model:userId="listQuery.userId" style="width: 200px"></selectuser>
            </el-form-item>
            <el-form-item label="合同结束期间：" :label-width="formLabelWidth">
              <el-date-picker v-model="listQuery.contractStartYear" type="date" placeholder="选择结束期起" size="small"
                value-format="YYYY-MM-DD" style="width: 120px" />-<el-date-picker v-model="listQuery.contractEndYear"
                type="date" placeholder="选择结束期止" size="small" value-format="YYYY-MM-DD" style="width: 120px" />
            </el-form-item>
          </el-form>
        </search>
        <span style="margin-left: 5px"><el-switch v-model="listQuery.contractState" active-text="欠费合同" :active-value="3"
            :inactive-value="1" inactive-text="" @change="getList" size="small"></el-switch></span>
        <span style="margin-left: 5px"><el-switch v-model="listQuery.contractState" active-text="到期合同" :active-value="2"
            :inactive-value="1" inactive-text="" @change="getList" size="small"></el-switch></span>
      </div>
      <div>
        <colSetting name="合同列表" btnName="显示列" @arrangeInfo="getInfo" @load="loadingV" />
        <el-button type="primary" size="small" plain icon="Camera" @click="tzUpload"
        :disabled="!$buttonStatus('hzkh_ywlx')">图章上传</el-button>
        <el-dropdown style="margin: 0 5px" v-if="$buttonStatus('hzkh_xzht')">
          <el-button type="primary" size="small" icon="CirclePlus">
            新增<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="add()">新增合同</el-dropdown-item>
              <el-dropdown-item @click="drView">导入合同</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-button type="primary" size="small" plain icon="SetUp" @click="toBusiness"
          :disabled="!$buttonStatus('hzkh_ywlx')">业务类型</el-button>
        <el-button type="primary" plain size="small" icon="FolderOpened" @click="daochu" :loading="daochuLoading"
          :disabled="!$buttonStatus('hzkh_htdc')">导出</el-button>
      </div>
    </div>
    <div class="loudou">
      <el-table :data="contractList" style="width: 100%; margin-top: 10px" border :height="contentStyleObj"
        @selection-change="handleSelectContract" v-loading="listLoading" stripe @sort-change="sortChange"
        @filter-change="tableFilterChange" class="date-icon">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" width="55" align="center" fixed />
        <el-table-column type="index" label="序号" width="55" align="center" fixed />
        <el-table-column prop="customerName" label="公司名称" align="left" fixed min-width="250"
          v-if="arrangeInfo.includes('公司名称')">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <div class="item_icon" style="
                  width: 95%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                ">
                <span>{{ scope.row.customerName }}</span>
              </div>
              <el-tooltip content="复制" placement="top" effect="dark">
                <i class="iconfont icon-fuzhi1" style="color: var(--themeColor, #17a2b8); cursor: pointer"
                  @click="$copyComName(scope.row.customerName)">
                </i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="contractNum" label="合同编号" min-width="120" sortable="custom" align="left"
          v-if="arrangeInfo.includes('合同编号')" />
        <el-table-column prop="contactsName" label="联系人" min-width="80" align="left"
          v-if="arrangeInfo.includes('联系人')" />
        <el-table-column prop="contactsPhone" label="联系电话" min-width="120" align="left"
          v-if="arrangeInfo.includes('联系电话')">
        </el-table-column>
        <el-table-column prop="categoryType" label="服务项目" min-width="150" align="left"
          v-if="arrangeInfo.includes('服务项目')" :filters="this.categories">
          <template #default="scope">
            <div class="div_p" v-for="(item, index) in scope.row.server" :key="index">
              <p style="text-align: left">{{ item.categoryName }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="contractStartTime" label="合同开始时间" width="130" align="left" sortable
          v-if="arrangeInfo.includes('合同开始时间')">
          <template #default="scope">
            <div class="div_p" v-for="(item, index) in scope.row.server" :key="index">
              <p style="text-align: left">{{ item.contractStartTime }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="contractEndTime" label="合同结束时间" width="130" align="left" sortable
          v-if="arrangeInfo.includes('合同结束时间')">
          <template #default="scope">
            <div class="div_p" v-for="(item, index) in scope.row.server" :key="index">
              <p style="text-align: left">{{ item.contractEndTime }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="contractAmount1" label="合同金额" min-width="120" align="left"
          v-if="arrangeInfo.includes('合同金额')">
          <template #default="scope">
            <div class="div_p" v-for="(item, index) in scope.row.server" :key="index">
              <p style="text-align: left">{{ item.contractAmount1 }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="paymentAmount1" label="付款金额" min-width="120" align="left"
          v-if="arrangeInfo.includes('付款金额')">
          <template #default="scope">
            <div class="div_p" v-for="(item, index) in scope.row.records" :key="index">
              <p style="text-align: left">{{ item.paymentAmount1 }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="contractSignTime" label="合同签订时间" width="130" align="left"
          v-if="arrangeInfo.includes('合同签订时间')" />
        <el-table-column prop="disCodes" label="销售经理" min-width="120" align="left" show-overflow-tooltip
          v-if="arrangeInfo.includes('销售经理')" />
        <el-table-column prop="contractImg" label="附件" width="70" align="center">
          <template #default="scope">
            <el-tooltip content="点击下载文件附件" placement="top" effect="dark">
              <i class="iconfont icon-wenjianleixing-biaozhuntu-wenjianjia" v-if="scope.row.contractFile"
                style="font-size: 16px; color: orange; cursor: pointer" @click="openFile(scope.row.contractFile)"></i>
            </el-tooltip>
            <el-tooltip content="点击查看图片附件" placement="top" effect="dark">
              <i class="iconfont icon-picture" v-if="scope.row.contractImg" style="
                  font-size: 16px;
                  color: var(--themeColor, #17a2b8);
                  cursor: pointer;
                  margin-left: 5px;
                " @click="open(scope.row)"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="合同备注" width="150" align="left" show-overflow-tooltip
          v-if="arrangeInfo.includes('备注')" />
        <el-table-column prop="remark" label="是否转外勤" width="110" align="center" show-overflow-tooltip>
          <template #default="scope">
            <span v-if="scope.row.taskType == '1'">
              <el-tag type="success"> 已转 </el-tag>
            </span>
            <span v-else>
              <el-tag type="danger"> 未转 </el-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" min-width="390" fixed="right">
          <template #default="scope">
            <el-button :disabled="!$buttonStatus('hzkh_htbj')" type="text" size="small" @click="addTaskQuest(scope.row)"
              style="margin: 0; padding: 5px 2px">
              <i class="iconfont icon-shenbaoshezhi" style="font-size: 23px"></i>转外勤</el-button>
            <el-button :disabled="!$buttonStatus('hzkh_htbj')" type="text" size="small"
              @click="updateContract(scope.row)" style="margin: 0; padding: 5px 2px">
              <i class="iconfont icon-bianji23"></i>编辑</el-button>
            <el-button type="text" plain size="small" @click="skSign(scope.row)" :disabled="!$buttonStatus('hzkh_skdj')"
              style="margin: 0; padding: 5px 2px"><i class="iconfont icon-jizhanghesuan"></i>收款登记</el-button>
            <el-button type="text" plain size="small" @click="checkskRecord(scope.row)"
              :disabled="!$buttonStatus('hzkh_skjl')" style="margin: 0; padding: 5px 2px"><i
                class="iconfont icon-hetongshenhe1"></i> 收款记录</el-button>
            <el-dropdown size="small" style="margin-left: 4px">
              <el-button type="primary" size="small" plain>
                更多<i class="iconfont icon-xiala-"></i>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item v-if="$buttonStatus('hzkh_htjy')" @click="breakContract(scope.row)">
                    解约
                  </el-dropdown-item>
                  <el-dropdown-item @click="moveAddCustomer(scope.row)">
                    {{ !scope.row.pushType ? "推送" : "再次推送" }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="top_select" style="margin: 10px 0 0 0">
      <div>
        <el-button v-if="$buttonStatus('htlb_fp')" type="primary" size="small"
          :disabled="this.sels.length === 0 || !$buttonStatus('hzkh_htfp')" @click="clickAllot"
          icon="Share">批量分配</el-button>
        <el-button type="danger" size="small" :disabled="this.sels.length === 0 || !$buttonStatus('hzkh_htsc')"
          @click="delAll()" icon="Delete" v-if="isAdmin == 1">批量删除</el-button>
      </div>
      <div class="pagination" style="margin-top: 0 !important">
        <qzf-pagination v-show="total > 0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
          @pagination="getList()" type="coopCustom" />
      </div>
    </div>

    <!-- 批量分配弹窗 -->
    <el-dialog :close-on-click-modal="false" v-model="allotFormVisible" title="分配" destroy-on-close>
      <span>销售经理：</span>
      <business code="coopCustom" v-model:userId="value"></business>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="allotFormVisible = false" size="small">取消</el-button>
          <el-button type="primary" @click="saveAllot()" size="small">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
    <renewContractDialog ref="renewContractDialog"></renewContractDialog>
    <!-- 导入模板 -->
    <el-dialog :close-on-click-modal="false" v-model="daoruFormVisible" title="导入" width="50%" destroy-on-close>
      <el-form size="small" label-position="right" label-width="5px">
        <el-form-item label="">
          <el-input style="width: 70%; margin-right: 10px" v-model="path"></el-input>
          <input size="small" ref="excel-upload-input" class="excel-upload-input" style="display: none" type="file"
            accept=".xlsx, .xls, .xml" @change="handleClick" />
          <el-button type="primary" @click="handleUpload()" size="small">请选择文件</el-button>
        </el-form-item>
      </el-form>
      <div>
        <a href="javascript:;" @click="loadForm()">下载模板</a>
        <div style="text-align: right">
          <el-button size="small" type="primary" @click="uploadExcel" v-loading="daoruLoading">上传</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" v-model="dialogVisible" title="七天内到期合同" width="90%">
      <el-table :data="termList" style="width: 100%" border :height="contentStyleObj">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="index" label="序号" width="55" align="center" fixed />
        <el-table-column prop="customerName" label="公司名称" align="left" show-overflow-tooltip min-width="240">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <div class="item_icon" style="
                  width: 95%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                ">
                <span>{{ scope.row.customerName }}</span>
              </div>
              <el-tooltip content="复制" placement="top" effect="dark">
                <i class="iconfont icon-fuzhi1" style="color: var(--themeColor, #17a2b8); cursor: pointer"
                  @click="$copyComName(scope.row.customerName)">
                </i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="contractNum" label="合同编号" align="center" min-width="120" />
        <el-table-column prop="categoryName" label="服务项目" align="center" min-width="120" />
        <el-table-column prop="contractEndTime" label="合同结束时间" min-width="120" align="center" />
        <el-table-column prop="disCodes" label="销售经理" align="center" min-width="120" show-overflow-tooltip />
      </el-table>
    </el-dialog>
    <daoruContract ref="daoruContract" />
    <collectionSign ref="collectionSign" @success="getList" />
    <collectionRecord ref="collectionRecord" />
    <signContract ref="signContract" @success="getList" />
  </div>
  <!-- 解约弹窗 -->
  <el-dialog :close-on-click-modal="false" v-model="rejectFormVisible" title="解约">
    <div>
      <span>解约原因：</span>
      <el-input v-model="rejectForm.reason" :rows="2" type="textarea" placeholder="请输入" style="margin-top: 20px" />
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="rejectFormVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="rejected()" size="small">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <addContract ref="addContract" />
  <el-drawer title="业务类型" v-model="visible" destroy-on-close size="60%">
    <businessType ref="businessType" />
  </el-drawer>
  <!-- 图片预览 -->
  <el-image-viewer v-if="showImagePreview" :zoom-rate="1.2" @close="showImagePreview = false" :url-list="imgSrc" />
  <pushNewly ref="pushNewly" @success="getList" />
  <!-- 转外勤 -->
  <addTask ref="addTask" @success="getList" />
  <!-- 上传图章 -->
  <stampUpload ref="stampUpload"  />
</template>

<script>
import {
  saveContractStatus,
  getCustomerList,
  delContract,
  exportContractNew,
  contractListNew,
  saveContractNew,
  categoryNameList,
  contractStatistics,
  batchDelContractNew,
  contractImportNew,
  termContractListNew,
  contractInfo
} from "@/api/crm";
import addTask from "./addTask.vue";
import business from "./business.vue";
import renewContractDialog from "../../contract/components/renewContractDialog.vue";
import daoruContract from "../../contract/components/daoruContract.vue";
import collectionSign from "./collectionSign.vue";
import collectionRecord from "./collectionRecord.vue";
import signContract from "./signContract.vue";
import addContract from "./addContract.vue";
import businessType from "../../crmSettimg/businessType.vue";
import pushNewly from "./pushNewly.vue";
import stampUpload from "./stampUpload.vue";
function deepClone(obj) {
  let objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        //判断ojb子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === "object") {
          objClone[key] = deepClone(obj[key]);
        } else {
          //如果不是，简单复制
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
}
export default {
  name: "contractList",
  components: {
    business,
    renewContractDialog,
    daoruContract,
    collectionSign,
    collectionRecord,
    signContract,
    addContract,
    businessType,
    pushNewly,
    addTask,
    stampUpload
  },
  data() {
    return {
      visible: false,
      dialogVisible: false,
      termList: [],
      isAdmin: this.$store.getters["user/user"].isAdmin,
      listLoading: false,
      count: 0,
      total: "",
      customerOptions: [],
      contractId: "",
      value: [],
      allotFormVisible: false,
      listQuery: {
        limit: 20,
        page: 1,
        contractState: 1,
        auditStatus: 3,
        customerId: null,
        contractEndTime: null,
        businessName: null,
        userId: null,
        disposable: null,
        contractSignTime: "",
        contractStartYear: "",
        ontractEndYear: "",
        categoryTerm: null,
        customerName: "",
      },
      date: "",
      id: "",
      contractList: [],
      sels: [],
      dialogStatus: "",
      textMap: {
        update: "编辑",
        create: "新增",
      },
      daochuLoading: false,
      daoruFormVisible: false,
      rawFile: "",
      path: "",
      contractDialogVisible: false,
      daoruLoading: false,
      formLabelWidth: "110px",
      rejectFormVisible: false,
      rejectForm: {},
      arrangeInfo: [
        "公司名称",
        "合同编号",
        "联系人",
        "联系电话",
        "合同签订时间",
        "服务项目",
        "合同开始时间",
        "合同结束时间",
        "合同金额",
        "付款金额",
        "销售经理",
        "备注",
      ],
      categories: [
        { text: "1", value: 199634 },
        { text: "记账解决", value: 91 },
      ],
      dataInfo: {},
      listQuery1: {
        contractStartTime: "",
        contractEndTime: "",
      },
      showImagePreview: false,
      imgSrc: [],
    };
  },
  created() {
    this.contentStyleObj = this.$getHeight(318);
  },
  mounted() {
    this.listQuery.limit = localStorage.getItem("coopCustom")
      ? localStorage.getItem("coopCustom") * 1
      : 20;
    this.getDay();
    this.getCateList();
    this.initBus();
  },
  methods: {
    // 重置
    cancel() {
      let originLimit = this.listQuery.limit;
      this.listQuery = {
        limit: originLimit,
        page: 1,
        contractState: 1,
        auditStatus: 3,
        userId: null,
        disposable: null,
        contractSignTime: "",
        contractStartYear: "",
        ontractEndYear: "",
        ContactsName: "",
      };
      this.getList();
    },
    // 转外勤
    addTaskQuest(row) {
      this.$refs.addTask.init(row, "contract");
    },
    getStatistics() {
      contractStatistics(this.listQuery1).then((res) => {
        this.dataInfo = res.data.data.list;
      });
    },
    getCateList() {
      categoryNameList({}).then((res) => {
        this.categories = res.data.data.list ? res.data.data.list : [];
      });
    },
    initBus() {
      this.$bus.off("contractUpdate");
      this.$bus.on("contractUpdate", (val) => {
        this.getList();
      });
      this.$bus.off("coopUpdate");
      this.$bus.on("coopUpdate", (val) => {
        this.getList();
      });
      this.$bus.off("businessTypeUpdate");
      this.$bus.on("businessTypeUpdate", (val) => {
        this.getCateList();
      });
    },
    getInfo(e) {
      this.arrangeInfo = e;
    },
    init(name) {
      this.listQuery.customerName = name;
      this.getList();
    },
    getTerm() {
      termContractListNew({}).then((res) => {
        if (res.data.msg == "success" && res.data.data.list != null) {
          this.termList = res.data.data.list;
          if (this.termList.length != 0) {
            this.dialogVisible = true;
          }
        }
      });
    },
    //查询合同
    getList() {
      this.getStatistics();
      this.listLoading = true;
      if (this.listQuery.categoryId) {
        this.listQuery.categoryName = this.listQuery.categoryId[1];
      } else {
        this.listQuery.categoryName = "";
      }
      contractListNew(this.listQuery).then((res) => {
        this.listLoading = false;
        if (res.data.msg == "success") {
          this.contractList = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
          this.count = res.data.data.count;
        }
      });
    },
    getDay() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month < 10 ? "0" + month : month;
      day = day <= 9 ? "0" + day : day;
      this.date = year + "-" + month + "-" + day;
      this.listQuery1.contractStartTime = year + "-" + month;
      this.listQuery1.contractEndTime = year + "-" + month;
    },
    //查询合作客户列表
    getCustomerList() {
      getCustomerList({ customerType: "2" }).then((res) => {
        if (res.data.msg == "success") {
          this.customerOptions = res.data.data.list;
        }
      });
    },
    drView() {
      this.daoruFormVisible = true;
      this.path = "";
      this.rawFile = "";
    },
    //导入合同
    loadForm() {
      window.open(
        "https://file.listensoft.net/mnt/v2/other/合同导入模板.xlsx" +
        "?" +
        new Date().getTime()
      );
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile;
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    uploadExcel() {
      if (!this.rawFile) {
        this.$qzfMessage("请选择文件", 1);
        return;
      }
      this.daoruLoading = true;
      const param = new FormData(); // 创建form对象
      param.append("file", this.rawFile, this.rawFile.name);
      contractImportNew(param).then((res) => {
        this.daoruLoading = false;
        if (res.data.msg == "success") {
          this.$qzfMessage("导入成功，请点击合同审核查看");
          this.getList();
          this.path = "";
          this.rawFile = "";
          this.$bus.emit("contractUncheck");
          this.daoruFormVisible = false;
          this.$refs.daoruContract.init(res.data.data);
        }
      });
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "请不要上传大于1M的文件。",
        type: "warning",
      });
      return false;
    },
    handleChange(val) {
      if (val != 1) {
        this.listQuery.categoryTerm = null;
      }
      this.getList();
    },
    //一次性变化设置
    handleDisposable(val) {
      if (val == 0) {
        this.contractForm.contractRenewTime = this.contractForm.contractEndTime;
      }
      if (val == 1) {
        this.contractForm.contractRenewTime = "";
      }
    },
    //续约合同
    renewContract() {
      this.$refs.renewContractDialog.getList();
    },
    //新增合同
    add() {
      this.$refs.addContract.init();
    },
    //编辑合同
    updateContract(row) {
      this.$refs.signContract.init(row, "update");
    },
    handleSelectContract(sels) {
      this.sels = sels;
    },
    //单独分配
    saveEveryAllot(row) {
      this.value = row.userId;
      this.allotFormVisible = true;
      this.contractId = row.id;
    },
    clickAllot() {
      this.contractId = "";
      this.value = [];
      this.allotFormVisible = true;
    },
    //批量分配跟进人
    saveAllot() {
      if (this.contractId == "") {
        if (this.value.length == 0) {
          this.$qzfMessage("请选择销售经理", 1);
          return;
        }
        let selects = [];
        this.sels.map((item) => {
          selects.push({
            id: item.id,
            userId: this.value,
          });
        });
        saveContractStatus(selects).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("分配成功");
            this.allotFormVisible = false;
            this.value = [];
            this.getList();
            this.$bus.emit("contractUncheck");
          }
        });
      } else {
        saveContractNew({
          id: this.contractId,
          userId: this.value,
        }).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("修改成功");
            this.allotFormVisible = false;
            this.contractId = "";
            this.value = [];
            this.getList();
            this.$bus.emit("contractUncheck");
          }
        });
      }
    },
    //单独删除
    delContract(row) {
      this.$confirm("确认删除合同吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delContract({ id: row.id }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
              this.$bus.emit("contractUncheck");
            }
          });
        })
        .catch(() => { });
    },
    //批量删除
    delAll() {
      this.$confirm("确认删除合同吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = [];
          this.sels.map((item) => {
            ids.push({
              id: item.id,
            });
          });
          batchDelContractNew(ids).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
              this.$bus.emit("contractUncheck");
            }
          });
        })
        .catch(() => { });
    },
    //解约合同
    breakContract(row) {
      contractInfo({ id: row.id }).then(res => {
        if (res.data.msg == "success") {
          this.rejectForm = deepClone(res.data.data.list);
          this.rejectFormVisible = true;
        }
      })
    },
    rejected() {
      if (!this.rejectForm.reason) {
        this.$qzfMessage("请输入解约原因", 1);
        return;
      }
      this.rejectForm.auditStatus = 4;
      saveContractNew(this.rejectForm).then((res) => {
        this.$qzfMessage("解约成功");
        this.rejectFormVisible = false;
        this.getList();
      });
    },
    //导出
    daochu() {
      this.daochuLoading = true;
      let ids = [];
      this.sels.map((v) => {
        ids.push(v.id);
      });
      let param = {
        ids: ids,
        query: this.listQuery,
      };
      exportContractNew(param).then((res) => {
        this.daochuLoading = false;
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
    //收款登记
    skSign(row) {
      this.$refs.collectionSign.init(row);
    },
    //收款记录
    checkskRecord(row) {
      this.$refs.collectionRecord.init(row);
    },
    //筛选
    tableFilterChange(filters) {
      const values = Object.values(filters);
      const value = values[0];
      this.listQuery.categoryNameId = value;
      this.getList();
    },
    //业务类型
    toBusiness() {
      this.visible = true;
    },
    open(row) {
      this.showImagePreview = true;
      this.imgSrc = row.contractImgs;
    },
    openFile(item) {
      window.open(item + "?" + new Date().getTime());
    },
    //推送到新增账套
    moveAddCustomer(row) {
      this.$refs.pushNewly.init(row);
    },
    // 表格排序
    sortChange(data) {
      const columnMap = {
        合同编号: "c.contract_num",
        合同开始时间: "se.contract_start_time",
        合同结束时间: "se.contract_end_time",
      };
      const defaultOrderBy = "c.updated_at desc";
      const label = data.column.label;
      const order = data.order === "descending" ? "desc" : "";
      this.listQuery.orderBy = `${columnMap[label]} ${order}, ${defaultOrderBy}`;
      this.getList();
    },
    //图章上传
    tzUpload(){
      this.$refs.stampUpload.init()
    },
  },
};
</script>

<style lang="scss" scoped>
.top_select {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}

.select_left {
  display: flex;
}

.every_select {
  margin-right: 5px;
}

.el-form-item {
  margin-bottom: 20px !important;
}

.count {
  font-size: 14px;
  margin-right: 5px;
}

:deep(.el-table__cell) {
  padding: 3px 0 !important;
}

.el-select {
  margin: 0 5px 0 0 !important;
}

:deep(.el-radio-button__original-radio:checked + .el-radio-button__inner) {
  background-color: var(--themeColor, #17a2b8);
  border-color: var(--themeColor, #17a2b8);
}

.div_p {
  line-height: 23px;
  border-bottom: 1px solid #efefef;
  height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:last-child {
    border-bottom: none;
  }
}

.content_data {
  background-color: #d8ecff;
  margin-bottom: 10px;
  font-size: 12px;
  position: relative;

  .iconfont {
    font-size: 18px;
    //color: var(--themeColor, #17a2b8);
    color: #17a2b8;
  }
}

.each {
  // width: 120px;
  width: 9%;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #333;
}

.each span {
  font-size: 13px;
  font-weight: 600;
  color: #000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.icon_bg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 30px;
}

.data_time {
  position: absolute;
  right: 10px;
  top: 10px;
}

.each_right {
  width: calc(100% - 35px);
  margin-left: 5px;
}

.icon-xiala- {
  font-size: 12px;
  margin-left: 5px;
}
</style>
<style lang="scss">
.el-table__column-filter-trigger i {
  color: #fff !important;
  width: 10px;
}

.el-table th.el-table__cell>.cell.highlight {
  color: #fff !important;
}
</style>
<style>
.loudou .el-icon-arrow-down:before {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e62e";
  margin-left: 250px;
}
</style>
