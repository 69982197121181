<template>
  <div class="app-container">
    <div class="top">
      <div v-if="showStatus" class="steps">
        <el-steps align-center>
          <el-step title="第一步：新增员工填写专项附加扣除" status="process"></el-step>
          <el-step title="第二步：添加/采集累计数据" status="process"></el-step>
          <el-step title="第三步：创建保存工资表" status="process"></el-step>
        </el-steps>
      </div>
      <div class="switch_icon" style="top: -10px" v-else @click="switchStep"><span>展开引导</span><el-icon style="transform: rotate(-90deg);"><d-arrow-left /></el-icon>
      </div>
      <div class="switch_icon" style="top: 45px" v-if="showStatus" @click="switchStep"><span>收起引导</span><el-icon style="transform: rotate(-90deg);"><d-arrow-right /></el-icon>
      </div>
      <img src="../../../../assets/customer.png" alt="" style="position: absolute;height: 69px;right: 0;top: 0;">
    </div>
    <div class="top_select">
      <div style="display: flex;">
        <el-input placeholder="请输入姓名/证件号码" style="width: 170px;" class="filter-item" clearable v-model="listQuery.name" size="small" @keyup.enter="getList"/>
        <qzf-button jz="false" button_code="yg_ss" class="filter-item"    type="primary" size="small" @success="getList()">
          <el-icon><Search /></el-icon> <span  >搜索</span>
        </qzf-button>
        <div style="margin-left: 5px;">
          <search @success="getList" @cancel="cancel">
            <el-form style="width:330px;" label-width="140px" size="small">
              <el-form-item label="员工状态:" >
                <el-select v-model="listQuery.status" filterable  style="width:120px;" >
                  <el-option label="全部" :value ="0"></el-option>
                  <el-option label="正常" :value ="1"></el-option>
                  <el-option label="非正常" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="扣除类型:" >
                <el-select v-model="listQuery.jcStatus" filterable  style="width:120px;">
                  <el-option label="全部" :value ="-1"></el-option>
                  <el-option label="6万扣除" :value ="1"></el-option>
                  <el-option label="正常扣除" :value="0"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="人员类型:" >
                <el-select v-model="listQuery.iDType" filterable  style="width:120px;"  >
                  <el-option label="全部" value =""></el-option>
                  <el-option label="境内人员" value ="1"></el-option>
                  <el-option label="境外人员" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="是否残疾:" >
                <el-select v-model="listQuery.disability" filterable  style="width:120px;">
                  <el-option label="全部" :value ="-1"></el-option>
                  <el-option label="是" :value ="1"></el-option>
                  <el-option label="否" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="本期是否添加工资表:" >
                <el-select v-model="listQuery.useStatus" filterable  style="width:120px;">
                  <el-option label="全部" :value ="-1"></el-option>
                  <el-option label="已添加工资表" :value ="1"></el-option>
                  <el-option label="未添加工资表" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </search>
        </div>
      </div>
      <div>
         <!-- <buttonStatus name="专项扣除" @success="zxfjcj()" ref="btnZxfj"></buttonStatus> -->
         <!-- <settings :type="['code_xzgs', 'code_gz_merge','code_salary_gs','code_salary_sb']" v-if="$buttonStatus('xc_yg_sz')"></settings> -->
        <qzf-video vid="63dd60f588d86d62e426078c1cda9dbb_6"></qzf-video>
        <el-dropdown style="margin-right:10px" v-if="$buttonStatus('xc_yg_xz')">
          <el-button type="primary" size="small" icon="CirclePlus">
            新增<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="addPersonnel()">境内人员</el-dropdown-item>
              <el-dropdown-item @click="addOverSeas"
                >境外人员</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-dropdown style="margin-right:10px" v-if="$buttonStatus('xc_yg_xz')">
          <el-button type="success" size="small" icon="CirclePlus">
            导入<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="openCom">导入境内人员</el-dropdown-item>
              <el-dropdown-item @click="daoruOversea"
                >导入境外人员</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <qzf-button jz="false" plain button_code="xc_yg_sc" type="danger" size="small" @success="handleClickDel"   >
          <el-icon><Delete /></el-icon> <span  >删除</span>
        </qzf-button>
        <el-dropdown @command="batchAll" style="margin-left:10px" size="small">
          <qzf-button jz="false" type="primary" size="small">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </qzf-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="f" v-if="$buttonStatus('xc_yg_dc')">
                <div @click="daochu">导出员工</div>
              </el-dropdown-item>
              <el-dropdown-item command="b" v-if="$buttonStatus('yg_zc')">
                <div>批量修改为正常</div>
              </el-dropdown-item>
              <el-dropdown-item command="a" v-if="$buttonStatus('yg_zc')">
                <div>批量修改为非正常</div>
              </el-dropdown-item>
              <el-dropdown-item command="z" v-if="$buttonStatus('yg_zc')">
                <div>批量修改任职受雇日期</div>
              </el-dropdown-item>
              <el-dropdown-item command="g" v-if="$buttonStatus('xc_yg_drzxfjkc')">
                <div @click="addallzxkc">导入专项附加扣除</div>
              </el-dropdown-item>
              <el-dropdown-item command="c" v-if="$buttonStatus('xc_yg_xgwlwkc')">
                <div>批量修改为6万扣除</div>
              </el-dropdown-item>
              <el-dropdown-item command="d" v-if="$buttonStatus('xc_yg_xgwzckc')">
                <div>批量修改为正常扣除</div>
              </el-dropdown-item>
              <el-dropdown-item command="m" v-if="$buttonStatus('xc_yg_xgwlwkc')">
                <div>批量修改减免费用为0</div>
              </el-dropdown-item>
              <el-dropdown-item command="p" v-if="$buttonStatus('xc_yg_xgwlwkc')">
                <div>批量修改为是雇员</div>
              </el-dropdown-item>
              <el-dropdown-item command="q" v-if="$buttonStatus('xc_yg_xgwlwkc')">
                <div>批量修改为非雇员</div>
              </el-dropdown-item>
              <el-dropdown-item command="w" v-if="$buttonStatus('xc_yg_qyyg')">
                <div @click="migrPerson">迁移员工</div>
              </el-dropdown-item>
              <el-dropdown-item command="h" v-if="$buttonStatus('xc_yg_dcsbb')">
                <div @click="daochuPerson">导出个人所得税扣缴申报表</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <p style="font-size:13px;color:red;">
        <i class="iconfont icon-gantanhao1" style="font-size: 13px;"></i>
        温馨提示：删除人员请谨慎！已有收入明细数据的人员如若删除将会影响申报数据！</p>
      <el-table stripe :height="contentStyleObj" @selection-change="handleSelectionChange" v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%;margin-top:5px">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" align="center" width="40"/>
        <el-table-column align="center" type="index" width="60" label="序号"> </el-table-column>
        <el-table-column label="姓名"  min-width="80" show-overflow-tooltip>
            <template #default="{row}">
                <span>{{row.name}}</span>
            </template>
        </el-table-column>
        <el-table-column label="证件类型" min-width="100">
          <template #default="{row}">
                <span>{{row.iDType}}</span>
          </template>
        </el-table-column>
        <el-table-column label="证件号码" min-width="180">
          <template #default="{row}">
                <span>{{row.iDNo}}</span>
          </template>
        </el-table-column>
        <el-table-column label="手机号" min-width="100">
          <template #default="{row}">
                <span>{{row.tel}}</span>
          </template>
        </el-table-column>
        <el-table-column label="性别" min-width="60">
          <template #default="{row}">
            <span v-if="row.sex == 1">男</span>
                <span v-else>女</span>
          </template>
        </el-table-column>
        <el-table-column label="出生日期" min-width="100">
          <template #default="{row}">
                <span>{{row.birthday}}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否雇员" min-width="80">
          <template #default="{row}">
            <span v-if="row.isEmployee == 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column label="任职受雇日期" min-width="120">
          <template #default="{row}">
                <span>{{row.joinDate}}</span>
          </template>
        </el-table-column>
        <el-table-column label="离职日期" min-width="100">
          <template #default="{row}">
                <span>{{row.departureDate}}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否股东" min-width="80">
          <template #default="{row}">
            <span v-if="row.stockholder == 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column label="国籍" min-width="80">
          <template #default="{row}">
                <span>{{row.country}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="部门" min-width="100">
          <template #default="{row}">
                <span>{{row.department}}</span>
          </template>
        </el-table-column> -->

        <el-table-column label="按6万扣除" min-width="100">
          <template #default="{row}">
            <span v-if="row.jcStatus == 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column label="减免费用为0" min-width="80" show-overflow-tooltip>
          <template #default="{row}">
            <span v-if="row.gsjm0 == 1">个税减免为0</span>
            <span v-else-if="row.gsjm0 == 2">经营所得减免为0</span>
            <span v-else-if="row.gsjm0 == 3">全部减免为0</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column label="人员状态" min-width="90">
          <template #default="{row}">
          <qzf-button jz="false" :disabled="!$buttonStatus('yg_zc')"  type="success" v-if="row.status == '1'" size="small" class="tablebutton" @success="editStatus(row,1)" plain>正常</qzf-button>
          <qzf-button jz="false" :disabled="!$buttonStatus('yg_zc')" type="danger" v-else size="small" class="tablebutton" @success="editStatus(row,2)" plain>非正常</qzf-button>
          </template>   
        </el-table-column>
        <el-table-column label="操作" align="center" min-width="120" class-name="sizeBor small-padding fixed-width top_right_btns">
          <template #default="{row}">
            <el-tooltip content="编辑" placement="top" v-if="$buttonStatus('xc_yg_bj')" effect="dark">
              <i class="iconfont icon-bianji iconStyle" @click="editDomesticPersonnel(row)"></i>
            </el-tooltip>
            <el-tooltip content="删除" placement="top" v-if="$buttonStatus('yg_zc')" effect="dark">
              <i class="iconfont icon-shanchu iconStyle" @click="handleModifyStatus(row,'deleted')"></i>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
  </div>
  <!-- 迁移员工 -->
  <el-dialog :close-on-click-modal="false" title="迁移员工设置" v-model="dialogVisible" width="450px">
    <el-form :model="form">
      <el-form-item label="是否清空已存在的员工信息：">
        <el-radio-group v-model="form.empty">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="saveSet()" size="small">确定</el-button>
      </span>
    </template>

  </el-dialog>

  <qzf-import-back :hideButton="true" @success="getList()" :comId="comId" :period2="period" ref="importBck" from="员工"></qzf-import-back>
  <qzf-import-back :hideButton="true" @success="getList()" :comId="comId" :period2="period" ref="importBck1" from="专项扣除"></qzf-import-back>
  <qzf-import-back :hideButton="true" @success="getList()" :comId="comId" :period2="period" ref="importBck2" from="境外人员"></qzf-import-back>
  <addEmployee ref="addEmployee" :type1="this.type" @success="getList" :comId="comId" :from="this.from"></addEmployee>
  <changeStatus ref="changeStatus" :ids="this.ids"></changeStatus>
  <exportEmployee ref="exportEmployee" :comId="comId" :period="period" :listQuery="listQuery"></exportEmployee>
  <batchJoinDate ref="batchJoinDate" @success="getList" :period="period"/>
  <batchGsjm ref="batchGsjm" @success="getList" />
  <batchDepartureDate ref="batchDepartureDate" @success="getList" />
</template>

<script>
import { exportReport } from '@/api/printSet'
import { salaryList,delEaEmployee,saveEmployees ,employeeMigration } from "@/api/salary.js"
import addEmployee from './addEmployee.vue'
import changeStatus from './changeStatus.vue'
import exportEmployee from '@/components/batchEmployeeSet/exportEmployee.vue'
import batchJoinDate from "@/components/batchEmployeeSet/batchJoinDate.vue";
import batchGsjm from "@/components/batchEmployeeSet/batchGsjm.vue";
import batchDepartureDate from "@/components/batchEmployeeSet/batchDepartureDate.vue";
export default {
  name: 'employee',
  components: {
      addEmployee,
      changeStatus,
      exportEmployee,
      batchJoinDate,
      batchGsjm,
      batchDepartureDate,
  } ,
  data() {
    return{
      comId:this.$store.getters["user/comInfo"].comId*1,
      period:this.$store.getters["user/comInfo"].period,
      listQuery: {
        status: 1,
        page: 1,
        limit:20,
        name: '',
        idNo: '',
        jcStatus:-1,
        useStatus:-1,
        iDType:"",
        disability:-1
      },
      list:[],
      ids:[],
      listLoading: false,
      total: 0,
      type: '',
      temp:{},
      dialogVisible:false,
      form:{},
      showStatus:true,
      from:""
    }
  },
  created() {
    this.contentStyleObj= this.$getHeight(290)
    this.getList()
  },
  methods: {
    handleSelectionChange(e){
      this.ids = e
    },
    switchStep(){
      this.showStatus = !this.showStatus
      if(this.showStatus){
        this.contentStyleObj = this.$getHeight(290)
      }else{
        this.contentStyleObj = this.$getHeight(220)
      }
    },
    // 更多
    batchAll(command){
      if(command == 'a'){
        this.batchSave()
      }else if(command == 'b'){
        this.batchSaveNormal()
      }else if(command == 'c'){
        this.batchSaveJcStatus(1)
      }else if(command == 'd'){
        this.batchSaveJcStatus(0)
      }else if(command == 'z'){
        this.batchEditJoinDate()
      }else if(command == 'm'){
        this.batchSaveGsJm()
      }else if(command == 'p'){
        this.batchSaveGy(1)
      }else if(command == 'q'){
        this.batchSaveGy(2)
      }
    },
    //批量修改任职受雇日期
    batchEditJoinDate(){
      if(this.$checkSettleStatus()) return
      if(this.ids.length == 0){
        this.$message.error('至少选择一个')
        return
      }
      this.$refs.batchJoinDate.init(this.ids)
    },
    batchSaveNormal(){
      if(this.$checkSettleStatus()) return
      if(this.ids.length == 0){
        this.$message.error('至少选择一个')
        return
      }
      this.ids.map(v=>{
        v.departureDate = ""
        v.status = 1
        // v.isEmployee = 1
      })
      saveEmployees(this.ids).then(res=>{
        if(res.data.msg == 'success'){
          this.$message.success('修改成功')
        }
      })
    },
    // 是否按6万扣除
    batchSaveJcStatus(status){
      if(this.$checkSettleStatus()) return
      if(this.ids.length == 0){
        this.$message.error('至少选择一个')
        return
      }
      this.ids.map(v=>{
        v.jcStatus = status
      })
      saveEmployees(this.ids).then(res=>{
        if(res.data.msg == 'success'){
          this.$message.success('修改成功')
        }
        this.getList()
      })
    },
    // 减免费用为0
    batchSaveGsJm(){
      if(this.$checkSettleStatus()) return
      if(this.ids.length == 0){
        this.$message.error('至少选择一个')
        return
      }
      this.$refs.batchGsjm.init(this.ids)
    },
    // 是否为雇员
    batchSaveGy(status){
      if(this.$checkSettleStatus()) return
      if(this.ids.length == 0){
        this.$message.error('至少选择一个')
        return
      }
      this.ids.map(v=>{
        v.isEmployee = status
      })
      saveEmployees(this.ids).then(res=>{
        if(res.data.msg == 'success'){
          this.$message.success('修改成功')
        }
        this.getList()
      })
    },
    //批量删除
    handleClickDel(){
      if(this.ids.length == 0){
          this.$qzfMessage("请至少选择一个",1)
        }else{
          this.$confirm("确定删除选项吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            let idss=[]
            this.ids.map(item=>{
              idss.push({id:item.id})
            })
            delEaEmployee(idss).then(res=>{
              if(res.data.msg == "success"){
                this.$qzfMessage("删除成功")
                this.getList()
              }else{
                this.$qzfMessage("删除失败",1)
              }
            })
          }); 
        }
    },
    //编辑
    editDomesticPersonnel(row) {
      this.type = '编辑人员'
      this.from = row.iDType == '居民身份证' ? 'inside' : 'oversea'
      let data = this.$qzfCopy(row)
      this.$nextTick(()=>{
        this.$refs.addEmployee.openDialog(data)
      })
    },
    //新增
    addPersonnel() {
      if(this.$checkSettleStatus()) return
      this.type = '新增境内人员'
      this.from = 'inside'
      this.$nextTick(()=>{
        this.$refs.addEmployee.openDialog()
      })
    },
    //新增境外人员
    addOverSeas(){
      if(this.$checkSettleStatus()) return
      this.type = '新增境外人员'
      this.from = 'oversea'
      this.$nextTick(()=>{
        this.$refs.addEmployee.openDialog()
      })
    },
    batchSave() {
      if(this.$checkSettleStatus()) return
      if(this.ids.length == 0){
        this.$message.error('至少选择一个')
        return
      }
      this.$refs.changeStatus.openDialog()
    },
    //里边删除
    handleModifyStatus(row) {
      if(this.$checkSettleStatus()) return
      this.$confirm('该人员已存在薪酬数据，修改人员信息将影响薪酬数据，请确定是否继续此操作', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$confirm('确定要删除吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
        delEaEmployee([{id: row.id}]).then(res=>{
          if(res.data.msg != "success") {
            this.$confirm(res.data.msg, '信息', {
              confirmButtonText: '确定',
              type: 'warning'
            })
            return
          }
          this.$message({
            type: 'success',
            message: '删除成功'
          });
          this.getList()
        })
      })
          
      })
    },
    //展示列表
    getList(){
      this.listLoading = true
      salaryList(this.listQuery).then(res=>{
        this.listLoading = false
        this.list = res.data.data.list ? res.data.data.list : []
        this.total = res.data.data.total
      })
    },
    cancel(){
      this.listQuery = {
        status: 1,
        page: 1,
        limit:20,
        name: '',
        idNo: '',
        jcStatus:-1,
        useStatus:-1,
        iDType:"",
        disability:-1
      }
      this.getList()
    },
    openCom(){
      if(this.$checkSettleStatus()) return
      this.$refs.importBck.handImport()
    },
    daoruOversea(){
      if(this.$checkSettleStatus()) return
      this.$refs.importBck2.handImport()
    },
    addallzxkc(){
      this.$refs.importBck1.handImport()
    },

    // 导出弹窗
    daochu(){
      this.$refs.exportEmployee.init(this.ids)
    },
    editStatus(e,status) {
      if(this.$checkSettleStatus()) return
      this.temp = Object.assign({}, e) // copy obj
      if(status == 2){
        this.$confirm('确定恢复该数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.temp.departureDate = ''
        this.temp.status = 1
          saveEmployees([this.temp]).then(res=>{
            if(res.data.msg == 'success'){
              this.getList()
              this.$qzfMessage("修改成功")
            }  
          })
        })
        return false
      }
      this.$confirm('确定此员工离职', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
       this.$refs.batchDepartureDate.init(e)
      })
    },
    daochuPerson(){
      let param = {
        period:this.$store.getters['user/comInfo'].period,
        comIds:[this.$store.getters['user/comInfo'].comId*1],
        bookNames:['grsdssb']
      }
      exportReport(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    //迁移员工
    migrPerson(){
      this.form.period = this.$store.getters['user/comInfo'].period;
      this.form.comId = this.$store.getters['user/comInfo'].comId*1;
      this.form.empty = true
      this.dialogVisible = true
    },
    saveSet(){
      employeeMigration(this.form).then(res=>{
        if(res.data.msg == "success"){
          this.dialogVisible = false
          this.$qzfMessage('迁移成功');
          this.getList()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
 
 .iconStyle{
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor,#17a2b8);
  font-size: 18px;
}
.el-table--mini .el-table__cell {
    padding: 0;
}
.pagination{
  margin-top: 15px;
}
.top_select{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 5px;
}
.steps{
  width: 1080px;
  margin: 0 auto;
  padding: 6px 0;
}
.top{
  position: relative;
  background-image:linear-gradient(180deg, var(--themeColorStepBack,#17a2b833) 0%, rgba(25, 182, 199, 0) 63%);
  box-shadow: 0 2px 10px #ddd;
}
:deep(.el-step__line){
  background-color: var(--themeColor, #17a2b8);
}
:deep(.el-step.is-horizontal .el-step__line){
  height:1px;
}
:deep(.el-step__icon-inner){
  font-weight: 500;
}
.switch_icon{
  position: absolute;
  right:2px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  z-index: 1;
  display: flex;
  align-items: center;
  span{
    font-size: 12px;
    margin-right: 2px;
  }
}
:deep(.el-step__title){
  font-size: 13px;
}
:deep(.el-step__icon){
  font-size: 13px;
}
:deep(.el-step__title.is-process){
  font-weight: 500;
  color: #666;
}
:deep(.el-step__icon.is-text){
  border: 1px solid;
  background-color: var(--themeColor, #17a2b8);
  color: #fff;
}
:deep(.el-dropdown-menu__item){
  display: block;
}
:deep(.el-radio){
  height: 50px;
  align-items: flex-start;
  margin-right: 23px;
}
:deep(.el-radio__inner){
  margin-top: 3px;
}
</style>