<template>
  <div>
    <div class="select">
      <div class="every_select">
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <el-input
          placeholder="请输入公司名称"
          v-model="listQuery.customerName"
          style="width: 200px; margin-right: 10px"
          size="small"
          @keyup.enter="getList"
          clearable
        />
        <el-button type="primary" @click="getList()" size="small" icon="Search"
          >搜索</el-button
        >
      </div>
      <div>
        <colSetting
          name="解约合同"
          btnName="显示列"
          @arrangeInfo="getInfo"
          @load="loadingV"
        />
      </div>
    </div>
    <el-table
      :data="startsList"
      style="margin-top: 10px"
      border
      :height="contentStyleObj"
      v-loading="listLoading"
      stripe
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <!-- <el-table-column type="selection" width="55" align="center" fixed /> -->
      <el-table-column
        type="index"
        label="序号"
        width="70"
        align="center"
        fixed
      />
      <el-table-column
        prop="customerName"
        label="公司名称"
        align="left"
        fixed
        min-width="250"
        v-if="arrangeInfo.includes('公司名称')"
      >
        <template #default="scope">
          <div style="display: flex;align-items: center;">
            <div
              class="item_icon"
              style="width: 95%; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
            >
              <span>{{ scope.row.customerName }}</span>
            </div>
            <el-tooltip content="复制" placement="top" effect="dark">
              <i
                class="iconfont icon-fuzhi1"
                style="color:var(--themeColor,#17a2b8);cursor: pointer;"
                @click="$copyComName(scope.row.customerName)"
              >
              </i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="contractNum"
        label="合同编号"
        min-width="120"
        align="left"
        v-if="arrangeInfo.includes('合同编号')"
      />
      <el-table-column
        prop="contactsName"
        label="联系人"
        min-width="80"
        align="left"
        v-if="arrangeInfo.includes('联系人')"
      />
      <el-table-column
        prop="contactsPhone"
        label="联系电话"
        min-width="120"
        align="left"
        v-if="arrangeInfo.includes('联系电话')"
      />
      <el-table-column
        prop="categoryType"
        label="服务项目"
        min-width="150"
        align="left"
        v-if="arrangeInfo.includes('服务项目')"
        :filters="this.categories"
      >
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.server"
            :key="index"
          >
            <p style="text-align: left;">{{ item.categoryName }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="contractStartTime"
        label="合同开始时间"
        width="130"
        align="left"
        v-if="arrangeInfo.includes('合同开始时间')"
      >
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.server"
            :key="index"
          >
            <p style="text-align: left;">{{ item.contractStartTime }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="contractEndTime"
        label="合同结束时间"
        width="130"
        align="left"
        v-if="arrangeInfo.includes('合同结束时间')"
      >
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.server"
            :key="index"
          >
            <p style="text-align: left;">{{ item.contractEndTime }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="contractAmount1"
        label="合同金额"
        min-width="120"
        align="left"
        v-if="arrangeInfo.includes('合同金额')"
      >
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.server"
            :key="index"
          >
            <p style="text-align: left;">{{ item.contractAmount1 }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="paymentAmount1"
        label="付款金额"
        min-width="120"
        align="left"
        v-if="arrangeInfo.includes('付款金额')"
      >
        <template #default="scope">
          <div
            class="div_p"
            v-for="(item, index) in scope.row.records"
            :key="index"
          >
            <p style="text-align: left;">{{ item.paymentAmount1 }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="contractSignTime"
        label="合同签订时间"
        width="130"
        align="left"
        v-if="arrangeInfo.includes('合同签订时间')"
      />
      <el-table-column
        prop="disCodes"
        label="销售经理"
        min-width="120"
        align="left"
        show-overflow-tooltip
        v-if="arrangeInfo.includes('销售经理')"
      />
      <el-table-column
        prop="remark"
        label="备注"
        width="150"
        align="left"
        show-overflow-tooltip
        v-if="arrangeInfo.includes('备注')"
      />
      <el-table-column
        prop="reason"
        label="解约原因"
        width="150"
        align="center"
        v-if="arrangeInfo.includes('解约原因')"
        fixed="right"
      />
      <el-table-column
        label="操作"
        align="center"
        min-width="100"
        fixed="right"
      >
        <template #default="scope">
          <el-button
            :disabled="!$buttonStatus('hzkh_htsc')"
            type="danger"
            size="small"
            @click="delContract(scope.row)"
            plain
            v-if="isAdmin == 1"
            icon="Delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="select">
      <div></div>
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
        type="coopCustom"
      />
    </div>
  </div>
</template>

<script>
import { contractListNew, delContract } from "@/api/crm";
import selectBusiness from "../../components/selectBusiness.vue";
import business from "./business.vue";
export default {
  name: "contractExamine",
  components: {
    selectBusiness,
    business,
  },
  data() {
    return {
      isAdmin: this.$store.getters["user/user"].isAdmin,
      listLoading: false,
      sels: [],
      total: "",
      rejectFormVisible: false,
      listQuery: {
        limit: 20,
        page: 1,
        auditStatus: 4,
        customerName: null,
      },
      rejectForm: {
        auditStatus: 2,
        id: "",
        remark: "",
      },
      startsList: [],
      contractDialogVisible: false,

      arrangeInfo: [
        "公司名称",
        "合同编号",
        "联系人",
        "联系电话",
        "合同签订时间",
        "服务项目",
        "合同开始时间",
        "合同结束时间",
        "合同金额",
        "付款金额",
        "销售经理",
        "备注",
      ],
    };
  },
  mounted() {
    this.listQuery.limit = localStorage.getItem('coopCustom') ? localStorage.getItem('coopCustom')*1 : 20
    this.contentStyleObj = this.$getHeight(228);
    this.getList();
  },

  methods: {
    getList() {
      this.listLoading = true;
      contractListNew(this.listQuery).then((res) => {
        this.listLoading = false;
        if (res.data.msg == "success") {
          this.startsList = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
    handleSelectContract(sels) {
      this.sels = sels;
    },
    getInfo(e) {
      this.arrangeInfo = e;
    },
    //单独删除
    delContract(row) {
      this.$confirm("确认删除合同吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delContract({ id: row.id }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
              this.$bus.emit("contractUncheck");
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  display: flex;
  justify-content: space-between;
}
.div_p {
  line-height: 23px;
  border-bottom: 1px solid #efefef;
  height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child {
    border-bottom: none;
  }
}
</style>
