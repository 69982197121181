<template>
  <el-dialog :close-on-click-modal="false" v-model="visible" title="图章上传" width="350px">
    <upload @uploadimgzj="getUrl" style="margin-left: 0" :images="imgUrl" />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="rejectFormVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="saveImg()" size="small">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref } from "vue";
import { ElMessage } from "element-plus";
import { saveStampImg, stampImgInfo } from "@/api/crm";
import upload from "@/components/uploadImg/upload.vue";

const visible = ref(false)
const imgUrl = ref("")
const init = () => {
  visible.value = true
  stampImgInfo({}).then(res => {
    if (res.data.msg == 'success') {
      imgUrl.value = res.data.data.info.stamp
    }
  })
}
const getUrl = (e) => {
  imgUrl.value = e
}
const saveImg = () => {
  if (!imgUrl.value) {
    ElMessage.warning('请上传图片')
    return
  }
  saveStampImg({ stampImg: imgUrl.value }).then(res => {
    if (res.data.msg == 'success') {
      ElMessage.success('保存成功')
      visible.value = false
    }
  })
}
defineExpose({ init })
</script>

<style scoped lang="scss"></style>
