<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogFormVisible" title="设置"  class="button_bg" width="30%">
    <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" label-width="140px">
      <el-form-item label="新增薪酬类型：" prop="wageType">
        <el-select v-model="temp.wageType" size="small" style="width: 200px;margin-left: 0;" @change="typeChange" :disabled="temp.id">
          <el-option v-for="item in sakayType" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <template v-if="temp.wageType == '个人生产经营所得(查账征收)' || temp.wageType == '个人生产经营所得(核定征收)'">
        <el-form-item label="薪酬所属期起：" prop="dateStart">
          <el-date-picker size="small" v-model="temp.dateStart" type="month" placeholder="选择薪酬所属期" value-format="YYYYMM" style="width: 200px"></el-date-picker>
        </el-form-item>
        <el-form-item label="薪酬所属期止：">
          <el-date-picker size="small" v-model="temp.dateEnd" type="month" placeholder="选择薪酬所属期" disabled value-format="YYYYMM" style="width: 200px"></el-date-picker>
        </el-form-item>

        <el-form-item label="企业类型：" prop="wageType">
          <el-select v-model="temp.comType"  size="small" style="width: 200px;margin-left: 0;">
            <el-option label="个体工商户" value="个体工商户"></el-option>
            <el-option label="个人独资企业" value="个人独资企业"></el-option>
            <el-option label="合伙企业" value="合伙企业"></el-option>

          </el-select>
        </el-form-item>
        <el-form-item label="征收方式：" prop="wageType">
          <el-select disabled v-model="zsfs"  size="small" style="width: 200px;margin-left: 0;">
            <el-option label="据实预缴" value="据实预缴"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-if="temp.wageType == '正常工资薪资' || temp.wageType == '劳务报酬所得'">
        <el-form-item label="支付方式：" prop="paymentMode">
          <el-select v-model="temp.paymentMode" size="small" style="width: 200px;margin-left: 0;">
            <el-option v-for="item in paytype" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="银行账户：" prop="paySubjectId" v-if="temp.paymentMode === '银行' || temp.paymentMode === '次月银行'">
          <subject-list subjectName="" v-model:subjectId="temp.paySubjectId" v-model:fzhsItemId="temp.payFzhsId" :codes="['1002']" style="width: 200px;margin-left: 0;"></subject-list>
        </el-form-item>
        <el-form-item label="现金：" prop="paySubjectId" v-if="temp.paymentMode === '现金' || temp.paymentMode === '次月现金'">
          <subject-list subjectName="" v-model:subjectId="temp.paySubjectId" v-model:fzhsItemId="temp.payFzhsId" :codes="['1001']" style="width: 200px;margin-left: 0;"></subject-list>
        </el-form-item>
        <el-form-item label="其他：" prop="paySubjectId" v-if="temp.paymentMode === '其他' || temp.paymentMode === '次月其他'">
          <subject-list subjectName="" v-model:subjectId="temp.paySubjectId" v-model:fzhsItemId="temp.payFzhsId" :payCode="['2241']" style="width: 200px;margin-left: 0;"></subject-list>
        </el-form-item>
      </template>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
      <el-button type="primary" @click="createData()" size="small">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { saveEaSalarySheet } from '@/api/salary.js'
export default {
  name: 'addSalary',
  data() {
    return{
      dialogFormVisible:false,
      temp: {
        comType: "个体工商户",
        zsfs: "据实预缴",
        wageType:'正常工资薪资',
        paymentMode: '无',
        period: '',
        comId: '',
        bankAccountId: '',
        paySubjectId: 0,
        payFzhsId: 0,
      },
      rules: {
        wageType:[{ required: true, message: "薪酬类型不能为空", trigger: "change" }],
        dateStart:[{ required: true, message: "薪酬所属期不能为空", trigger: "blur" }],
        paymentMode:[{ required: true, message: "支付方式不能为空", trigger: "change" }],
        paySubjectId:[{ required: true, message: "支付科目不能为空", trigger: "change" }],
      },
      dialogStatus: '',
      textMap: {
        update: '修改薪酬',
        create: '新增薪酬'
      },
      sakayType:[{
          value: '正常工资薪资',
          label: '正常工资薪资'
        }, 
        {
          value: '个人生产经营所得(查账征收)',
          label: '个人生产经营所得(查账征收)'
        }, 
        {
          value: '个人生产经营所得(核定征收)',
          label: '个人生产经营所得(核定征收)'
        }, 
        {
          value: '劳务报酬所得',
          label: '劳务报酬所得'
        },
        {
          value: '全年一次性奖金',
          label: '全年一次性奖金'
        }
      ],
      paytype: [
        {
          value: '无',
          label: '只计提，不发放'
        },
        {
          value: '银行',
          label: '当月计提，银行发放'
        },
        {
          value: '现金',
          label: '当月计提，现金发放'
        },
        {
          value: '其他',
          label: '当月计提，其他发放'
        },
        {
          value: '次月银行',
          label: '当月计提，次月银行发放'
        },
        {
          value: '次月现金',
          label: '当月计提，次月现金发放'
        },
        {
          value: '次月其他',
          label: '当月计提，次月其他发放'
        }
      ],
      zsfs: "据实预缴",
      contentStyleObj:{}, //高度变化
      } 
  },
  methods: {
    //弹窗
    openSalary(row){
      this.temp = {
        comType: "个体工商户",
        zsfs: "据实预缴",
        wageType:'正常工资薪资',
        paymentMode: '无',
        period: '',
        comId: '',
        bankAccountId: '',
        paySubjectId: 0,
        payFzhsId: 0,
        dateEnd:this.$store.getters['user/comInfo'].period
      }
      //console.log(this.temp.dateEnd);
      if(row){
        this.temp = Object.assign({},row)
      }
      this.typeChange()
      this.dialogFormVisible = true
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          if((this.temp.wageType == '正常工资薪资' || this.temp.wageType == '劳务报酬所得') && this.temp.paymentMode != '无' && !this.temp.paySubjectId){
            this.$qzfMessage("请选择科目",1)
            return
          }
          this.temp.comId = this.temp.comId*1
          this.temp.bankAccountId = this.temp.bankAccountId*1
          saveEaSalarySheet(this.temp).then(e => {
            if(e.data.msg == 'success'){
              this.$qzfMessage("创建成功")
              this.$emit("success")
              this.dialogFormVisible = false
            }
          })
        }
      })
  },
    typeChange(){
      if(this.temp.wageType == '个人生产经营所得(核定征收)'){
        this.zsfs = '核定征收'
        this.temp.zsfs = '核定征收'
      }else{
        this.temp.zsfs = '据实预缴'
        this.zsfs = '据实预缴'
      }
    }
  }
}
</script>

<style>
.dialog-footer {
    text-align: right;
}
</style>